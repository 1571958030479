import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SetDefaultLanguageService } from 'src/app/shared/utils/setDefaultLanguage';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, UntypedFormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { GridDataSource, PagedRequest } from 'src/app/services/grid-data-source.service';
import { PartRestriction } from '../../models/part-restriction';
import { PartRestrictionService } from '../../services/part-restriction.service';
import { Sort} from '@angular/material/sort';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { AppInsightsService } from 'src/azure-monitor';
import { partsRestriction } from 'src/app/constants/page-names.const';

@Component({
  selector: 'app-part-restriction-grid',
  templateUrl: './part-restriction-grid.component.html',
  styleUrls: ['./part-restriction-grid.component.css']
})
export class PartRestrictionGridComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

  displayedColumns: string[] = ['part.partCode', 'part.description', 'risk', 'crisisReason', 'emergencyRoutine'];
  filterObject: any = {};
  partsRestriction: PartRestriction[] = [];
  customerControl = new UntypedFormControl('');
  updatedAt!: Date;
  showLastUpdate = false;
  isLogistic = false;
  public LocalStorage = new LocalStorageUtils();
  searchTerm!: string;

  dataSource = new MatTableDataSource<PartRestriction>(this.partsRestriction);

  constructor(
    public service: GridDataSource<PartRestriction>,
    public dataService: PartRestrictionService,
    private translateService: TranslateService,
    private setLang: SetDefaultLanguageService,
    private spinner: NgxSpinnerService,
    private getTranslationSvc: GetTranslationService,
    private toastr: ToastrService
  ) {
    this.translateService.setDefaultLang(this.setLang.setDefaultLanguage());
    this.translateService.use(localStorage.getItem('language') || this.setLang.setDefaultLanguage());

    var _dataService = this.dataService;
    this.filterObject.limit = 10;
    this.filterObject.page = 1;
    this.service.configureDataSource((request: PagedRequest<PartRestriction>) => _dataService.getAllPartsRestriction(this.filterObject.page, this.filterObject.limit, this.filterObject));
    this.service.datasource.subscribe(
      response => {

       this.spinner.show();
       this.partsRestriction = response;
       this.dataSource = new MatTableDataSource<PartRestriction>(this.partsRestriction);
       this.lastUpdated();
       this.setIsLogisticManager();
       setTimeout(() => {
         this.spinner.hide();
       }, 1000);
      });
  }

  ngOnInit(): void {
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 1000);

    AppInsightsService.logPageName(partsRestriction);
  }

  refreshGrid(){
    this.filterObject.limit = this.paginator.pageSize;
    this.service.refresh();
    this.lastUpdated();
  }

  lastUpdated(){
    this.showLastUpdate = false;
    if(this.partsRestriction.length > 0){
      this.updatedAt = this.partsRestriction[0].updatedAt;
      this.showLastUpdate = true;
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction == 'asc') {
      this.filterObject.fieldNameOrderBy = sortState.active;
      this.filterObject.isOrderByDesc = false;
    } else if (sortState.direction == 'desc') {
      this.filterObject.fieldNameOrderBy = sortState.active;
      this.filterObject.isOrderByDesc = true;
    } else {
      this.filterObject.fieldNameOrderBy = 'id';
      this.filterObject.isOrderByDesc = false;
    }
    this.service.refresh();
  }

  downloadReport() {
		if (confirm(this.getTranslationSvc.getTranslation("Deseja fazer download do arquivo .csv?")))
			this.dataService.downloadReport().subscribe(
				response => {
					let fileName = this.parseHeaders(response.headers)
					this.dataService.downloadFile(response.body, 'text/csv', fileName);
				}, error => {
					this.toastr.error(this.getTranslationSvc.getTranslation("Problema ao tentar fazer download do relatório"), this.getTranslationSvc.getTranslation('Erro'), { timeOut: 3000, extendedTimeOut: 3000 });
				})
	}

  parseHeaders(responseHeader: any) {
		let contentDisposition = responseHeader.get('content-disposition');
		return contentDisposition.split(';')[1].split('=')[1];
	}

  setIsLogisticManager(){
    this.isLogistic = this.LocalStorage.searchGroup(environment.LOGISTIC_MANAGER_GROUP_ID);
  }

  ngAfterViewChecked(){
    this.paginator._intl.itemsPerPageLabel = this.getTranslationSvc.getTranslation("Itens por página:");
    this.paginator._intl.firstPageLabel = this.getTranslationSvc.getTranslation("Primeira página");
    this.paginator._intl.lastPageLabel = this.getTranslationSvc.getTranslation("Última página");
    this.paginator._intl.nextPageLabel = this.getTranslationSvc.getTranslation("Próxima página");
    this.paginator._intl.previousPageLabel = this.getTranslationSvc.getTranslation("Página anterior");
  }

  onPageChange(event: any) {
    this.filterObject.page = event.pageIndex+1
    this.filterObject.limit = event.pageSize;
    this.refreshGrid();
  }

  search() {
    delete this.filterObject.searchKey;
    this.filterObject.page = 1;
    this.paginator.firstPage();

    if(this.searchTerm)
      this.filterObject.searchKey = this.searchTerm;

    this.refreshGrid();
  }
}
