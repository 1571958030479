import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { userDealer } from 'src/app/constants/page-names.const';
import { GridDataSource } from 'src/app/services/grid-data-source.service';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { AppInsightsService } from 'src/azure-monitor';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-dealer',
  templateUrl: './user-dealer.component.html',
  styleUrls: ['./user-dealer.component.css'],
  providers: [GridDataSource, DecimalPipe]
})
export class UserDealerComponent implements OnInit {
  @Output() clickMenuItem = new EventEmitter<string>();

  isSalesRepresentativeAdmin = false;
  public LocalStorage = new LocalStorageUtils();

  constructor() {
    this.setIsSalesRepresentativeAdmin();
  }

  ngOnInit(): void {
    this.clickMenuItem.emit('userDealer');
    AppInsightsService.logPageName(userDealer);
  }

  setIsSalesRepresentativeAdmin(){
    this.isSalesRepresentativeAdmin = this.LocalStorage.searchGroup(environment.SALES_REPRESENTATIVE_ADMIN_GROUP_ID);
  }
}
