import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { CopyQuotationComponent } from '../copy-quotation/copy-quotation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuotationService } from '../../services/quotation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UpInsertPartQuotationModel, UpInsertQuotationModel } from '../../models/quotation';
import { Observable, Subject, debounceTime } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { GridDataSource, ResponseResult } from 'src/app/services/grid-data-source.service';
import { DecimalPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PartQuotation } from 'src/app/shared/models/partQuotation';
import { Quotation } from 'src/app/shared/models/quotation';
import { Part } from 'src/app/shared/models/part';
import { BlockedPartsComponent } from '../blocked-parts/blocked-parts.component';
import { DealerGroup } from 'src/app/shared/models/dealerGroup';
import { Dealer } from 'src/app/shared/models/dealer';
import { EnumQuotationStatus } from 'src/app/shared/models/enumQuotationStatus';
import { EnumPlanningStatus } from 'src/app/shared/models/enumPlanningStatus';
import { WarningPart } from 'src/app/shared/models/warningPart';
import { WarningsPartComponent } from 'src/app/shared/component/warnings-part/warnings-part.component';
import { PlanningHasPart } from 'src/app/shared/models/planningHasPart';
import { PartOtherPlanningsComponent } from 'src/app/shared/component/part-other-plannings/part-other-plannings.component';
import { CommercialParameters } from 'src/app/modules/parameters/models/commercial-parameters';
import { CommercialParametersService } from 'src/app/shared/services/commercial-parameters.service';
import { KitListComponent } from 'src/app/shared/component/kit-list/kit-list.component';
import { CurrencyWithoutSymbolPipe } from 'src/app/shared/utils/currency-without-symbol.pipe';
import { NumberWithoutRoundingPipe } from 'src/app/shared/utils/number-without-rounding.pipe';
import { PlanningDialogComponent } from '../planning-dialog/planning-dialog.component';
import { DealerService } from 'src/app/shared/services/dealer.service';
import { DealerGroupService } from 'src/app/shared/services/dealer-group.service';
import { MessageListModalComponent } from 'src/app/shared/component/message-list-modal/message-list-modal.component';

@Component({
  selector: 'app-new-quotation',
  templateUrl: './new-quotation.component.html',
  styleUrls: ['./new-quotation.component.css'],
  providers: [GridDataSource, DecimalPipe, CurrencyWithoutSymbolPipe, NumberWithoutRoundingPipe]
})
export class NewQuotationComponent implements OnInit, AfterViewInit  {
  @ViewChild('falseClick') falseClick!: ElementRef<HTMLElement>;
  @ViewChild('divQuotationGrid', { static: false }) divQuotationGrid!: ElementRef;
  @ViewChild('quotationGrid', { static: false }) quotationGrid!: ElementRef;
  @ViewChild('divCardPrincipal', { static: false }) divCardPrincipal!: ElementRef;
  @ViewChild('fillerQuotationGrid', { static: false }) fillerQuotationGrid!: ElementRef;
  @ViewChild(MatSort) sort: MatSort = new MatSort;
  @Output() setHeaderTitle = new EventEmitter<string>();
  @ViewChild('divQuotationGrid') private scrollableDiv!: ElementRef;

  dealerGroupFilters!: DealerGroup[];
  dealerFilters!: Dealer[];
  partFilters!: Part[];
  dealerGroupDropdownSettings!: IDropdownSettings;
  dealerDropdownSettings!: IDropdownSettings;
  partDropdownSettings!: IDropdownSettings;
  dealerList!: Dealer[];
  selectedDealerGroup!: DealerGroup[];
  selectedDealer!: Dealer[];
  quotationName!: string;
  filter: Subject<string> = new Subject<string>();
  filterObject: any = {};
  partsQuotation: PartQuotation[] = [];
  dataSource = new MatTableDataSource<PartQuotation>(this.partsQuotation);
  displayedColumns: string[] = ['fixedPrice', 'discountPercent', 'partCode', 'warning', 'description', 'amount', 'priceWithoutDiscount', 'priceWithDiscount', 'profitabilityWithoutDiscount', 'profitabilityWithDiscount', 'promotion', 'boPart', 'lastPromotion', 'functionGroup', 'productGroup', 'kitInclude', 'part.c3stock', 'ranking', 'bulkpackOut', 'unitMeasurement', 'replacedBy', 'risk', 'monthlyForecast', 'stockedLpa', 'planningPart', 'partQuotationId'];
  hasParts = false;
  selectedFile: File = new File([], '');
  validQuotationData: boolean = false;
  allowSave: boolean = false;
  selectedPartId!: string | null;
  quotation!: Quotation;
  selectedParts: any[] = [];
  discountPackage: number = 0;
  applyPackageDiscount: boolean = false;
  partPlaceholder: string = 'Código ou descrição da peça';
  statusQuotation!: string;
  quotationId: string | null = null;
  creating: boolean = true;
  partsList!: Part[];
  blockedParts!: PartQuotation[];
  blocked: boolean = false;
  lastSearch: string = '';
  commercialParameters!: CommercialParameters;
  tryToPlan: boolean = false;
  hasPlanning: boolean = false;
  showDuplicatePartMessage: boolean = false;
  duplicatePartMessage!: string;
  amountMessage: string = this.translateService.instant('Quantidade deve ser maior que 0');
  multipleMessage: string = this.translateService.instant('Quantidade deve ser múltiplo de');
  page: number = 1;
  firstPage: number = 1;
  limit: number = 100;
  spinnerCount: number = 0;
  totalParts: number = 0;
  loadingParts: boolean = false;
  allLoaded: boolean = false;
  totalPercentage: number = 100;
  gridPercentageHeight: number = 50;
  gridLineHeight: number = 52;
  gridHeaderHeight: number = 73;

  constructor(private translateService: TranslateService,
    private getTranslationSvc: GetTranslationService,
    private modalService: NgbModal,
    public dataService: QuotationService,
    private spinner: NgxSpinnerService,
    public service: GridDataSource<PartQuotation>,
    private elementRef: ElementRef,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public commercialParametersService: CommercialParametersService,
    public dealerService: DealerService,
    public dealerGroupService: DealerGroupService){

    this.initializeSettings();
    this.getCommercialParameters();

    this.service.datasource.subscribe(
      response => {
        this.spinnerShow();
        this.dataSource = new MatTableDataSource<PartQuotation>(this.partsQuotation);
        this.spinnerHide();
      });

  }

  ngOnInit() {

    this.initializeSettings();
    this.getDealersList();
    this.getParts('');

    this.filter.pipe(debounceTime(1000)).subscribe((searchKey: string) => {
      if (!searchKey) {
        this.getParts('');
      } else {
        this.getParts(searchKey.toLowerCase());
      }
    });

    this.setPartPlaceholder();
  }

  initializeSettings() {
    this.dealerGroupDropdownSettings = {
      singleSelection: true,
      idField: 'dealerGroupId',
      textField: 'description',
      selectAllText: this.translateService.instant('Selecionar tudo'),
      unSelectAllText: this.translateService.instant('Limpar'),
      noDataAvailablePlaceholderText: this.translateService.instant('Nenhum dado encontrado'),
      itemsShowLimit: 1,
      allowSearchFilter: false,
    };

    this.dealerDropdownSettings = {
      singleSelection: true,
      idField: 'dealerId',
      textField: 'corporateName',
      selectAllText: this.translateService.instant('Selecionar tudo'),
      unSelectAllText: this.translateService.instant('Limpar'),
      noDataAvailablePlaceholderText: this.translateService.instant('Selecione o grupo econômico'),
      itemsShowLimit: 2,
      allowSearchFilter: false,
    };

    this.partDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'displayValue',
      selectAllText: this.translateService.instant('Selecionar tudo'),
      unSelectAllText: this.translateService.instant('Limpar'),
      noDataAvailablePlaceholderText: this.translateService.instant('Nenhum dado encontrado'),
      noFilteredDataAvailablePlaceholderText: this.translateService.instant('Nenhum dado encontrado'),
      itemsShowLimit: 2,
      allowSearchFilter: true,
      searchPlaceholderText: this.translateService.instant('Digite código ou descrição da peça')
    };
  }

  openCopyModal() {
    if(!this.validQuotationData){
      return;
    }

    const modalRef = this.modalService.open(CopyQuotationComponent, {size: 'xl' });
    modalRef.result.then((response) => {
      if(response){
        this.copyFromQuotation(response);
      }
    });
  }

  getDealersList(){
    this.spinnerShow();
    this.dealerService.listDealersByUser().subscribe(response => {
      this.dealerList = response.data.data;
      this.fillDealerGroup();
      this.spinnerHide();
    });
  }

  fillDealerGroup(){
    this.spinnerShow();
    this.dealerGroupService.listDealerGroupsByUser().subscribe(response => {
      this.dealerGroupFilters = response.data.data;
      this.spinnerHide();
      this.loadQuotation();
    });
  }

  onDealerGroupSelect(item: any){
    this.selectedDealer = [];
    this.dealerFilters = this.dealerList.filter(d => d.dealerGroupId === item.dealerGroupId);
    this.triggerFalseClick();
    this.validateFilledFields();
    this.calculateAllPartsProfitability();
  }

  onDealerGroupDeSelect(item: any){
    this.dealerFilters = [];
    this.selectedDealer = [];
    this.validateFilledFields();
    this.calculateAllPartsProfitability();
  }

  getParts(searchTerm: string){

    if(this.isSameSearchTerm(searchTerm)){
      return;
    }

    this.filterObject.searchKey = searchTerm;

    this.dataService.getParts(1, 9999, this.filterObject).subscribe(response => {
      if(response.data.total > 0) {
        this.setPartsList(response.data.data);
      }
    });
  }

  setPartsList(newList: Part[]){
    this.partsList = newList;
    for (const part of this.partsList) {
      part.displayValue = part.partCode + " - " + part.description;
    }

    this.removeFromPartFilters();
  }

  onFilterChange(item: any){
    this.filter.next(item);
    this.checkDuplicatePart(item.toString());
  }

  triggerFalseClick() {
    let el: HTMLElement = this.falseClick.nativeElement;
    el.click();
  }

  onDealerSelect(){
    this.validateFilledFields();
    this.triggerFalseClick();
    this.calculateAllPartsProfitability();
  }

  onDealerDeSelect(){
    this.validateFilledFields();
    this.calculateAllPartsProfitability();
  }

  onPartSelect(item: any){
    if(item)
      this.selectedPartId = item.id;
    this.triggerFalseClick();
  }

  onPartDeSelect(){
    this.selectedPartId = null;
  }

  addParts(quotation: Quotation | null) {

    if(!quotation){
      return;
    }
    let partsToAdd: PartQuotation[] = quotation.partsQuotation;

    if(!partsToAdd){
      return;
    }

    if(!this.validQuotationData){
      return;
    }

    this.spinnerShow();
    this.blockedParts = [];

    for (const pq of partsToAdd) {
      this.setAmount(pq);
      pq.fixedPrice = pq.fixedPrice ? pq.fixedPrice : 0;
      pq.discountPercent = pq.discountPercent ? pq.discountPercent : 0;
      this.setDisabledInputs(pq);
    }

    if(this.creating){
      this.partsQuotation = partsToAdd.filter(pq => !pq.blocked);
      this.blockedParts.push(...partsToAdd.filter(pq => pq.blocked));
    }
    else {
      this.partsQuotation = partsToAdd;
    }

    this.dataSource = new MatTableDataSource<PartQuotation>(this.partsQuotation);
    this.verifyHasParts();
    this.clearSelectedPart();
    this.removeFromPartFilters();
    this.spinnerHide();
    // this.hasBlockedParts();
    this.setSummary(quotation);
  }

  announceSortChange(sortState: Sort) {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'part.c3stock': return item.part.c3stock;
        case 'profitabilityWithoutDiscount': {
          console.log(item.profitabilityWithoutDiscount);
          if (item.profitabilityWithoutDiscount === null){
            return 0;
          }
          return item.profitabilityWithoutDiscount;
        }
        default: return item[property as keyof PartQuotation] as string
      }
    };
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;

    this.setSizes();
    const divElement = this.elementRef.nativeElement.querySelector('.div-card-principal');

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
      }
    });

    resizeObserver.observe(divElement);
  }

  setSizes() {
    if(!this.divCardPrincipal)
      return;

    const divCardPrincipalWidth = this.divCardPrincipal.nativeElement.offsetWidth;

    if(!this.divQuotationGrid)
      return;
    this.divQuotationGrid.nativeElement.style.width = `${divCardPrincipalWidth * 0.935}px`;

    const vhValue: number = window.innerHeight / this.totalPercentage;

    let totalHeight = this.gridPercentageHeight * vhValue;
    this.fillerQuotationGrid.nativeElement.style.height = `${totalHeight}px`

    if(!this.partsQuotation)
      return;

    if(this.partsQuotation.length <= 0)
      return;

    let gridHeight = (this.partsQuotation.length * this.gridLineHeight) + this.gridHeaderHeight;

    if(gridHeight > totalHeight)
      gridHeight = totalHeight;

    let fillerHeight = totalHeight - gridHeight;

    if(fillerHeight < 0){
      fillerHeight = 0;
    }

    this.divQuotationGrid.nativeElement.style.height = `${gridHeight}px`;
    this.fillerQuotationGrid.nativeElement.style.height = `${fillerHeight}px`

    if(!this.quotationGrid)
      return;

    this.quotationGrid.nativeElement.style.width = `${divCardPrincipalWidth * 0.935}px`;
  }

  verifyHasParts() {
    this.hasParts = this.partsQuotation.length > 0;
    this.canSave();
    if(!this.hasParts)
      return;

    this.setPartPlaceholder();
    this.setSizes();
  }

  onFileSelected(event: any) {
    if(!this.validQuotationData){
      return;
    }

    this.selectedFile = event.target.files[0];

    if (this.selectedFile) {
      this.getPartsQuotationFromFile();
    }

    event.target.value = null;
  }

  onDiscountChange(event: Event, rowIndex: number) {
    this.partsQuotation[rowIndex].fixedPriceDisabled = false;
    const inputValue = (event.target as HTMLInputElement).value;
    const sanitizedValue = inputValue.split('.')[0].replace(/[^\d]/g, '');
    const numberSanitized = Number(sanitizedValue)
    this.partsQuotation[rowIndex].discountPercent = numberSanitized;
    this.partsQuotation[rowIndex].fixedPrice = 0;
    if(numberSanitized > 100)
      this.partsQuotation[rowIndex].discountPercent = 100;

    if(this.partsQuotation[rowIndex].discountPercent > 0){
      this.partsQuotation[rowIndex].fixedPriceDisabled = true;
    }

    this.calculateProfitabilityOnChangeValue(rowIndex);
  }

  onFixedPriceChange(rowIndex: number) {
    this.partsQuotation[rowIndex].discountPercentDisabled = false;
    this.partsQuotation[rowIndex].discountPercent = 0;

    if(this.partsQuotation[rowIndex].fixedPrice > 0){
      this.partsQuotation[rowIndex].discountPercentDisabled = true;
    }

    this.calculateProfitabilityOnChangeValue(rowIndex);
  }

  onAmountChange(rowIndex: number){
    this.calculateProfitabilityOnChangeValue(rowIndex);
    this.verifyReplacedValue(rowIndex);
  }

  validateFilledFields(){
    this.validQuotationData = false;
    this.canSave();

    if(!this.selectedDealerGroup)
      return;

    if(!this.selectedDealer)
      return;

    if(!this.quotationName)
      return;

    if(this.selectedDealerGroup.length === 0)
      return;

    if(this.selectedDealer.length === 0)
      return;

    if(this.quotationName.length === 0)
      return;

    this.validQuotationData = true;
    this.canSave();
  }

  canSave(){
    this.allowSave = this.validQuotationData;
  }

  inputName(){
    this.validateFilledFields();
  }

  clearSelectedPart(){
    this.selectedParts = [];
    this.selectedPartId = null;
  }

  refreshGrid(){
    this.service.refresh();
    this.setSizes();
  }

  discountPackageInput(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    const sanitizedValue = inputValue.split('.')[0].replace(/[^\d]/g, '');
    const numberSanitized = Number(sanitizedValue)
    this.discountPackage = numberSanitized;
    if(numberSanitized > 100)
      this.discountPackage = 100;

    this.changePackageDiscount();
  }

  changePackageDiscount(){
    this.partsQuotation.forEach(p =>{
      p.discountPercentDisabled = this.applyPackageDiscount;
      p.fixedPriceDisabled = this.applyPackageDiscount || p.discountPercent !== 0;
    });

    if(!this.applyPackageDiscount)
      return;

    this.partsQuotation.forEach(p =>{
      p.discountPercent = this.discountPackage;
      p.fixedPrice = 0;
    });

    this.calculateAllPartsProfitability();
  }

  setPartPlaceholder(){
    this.partPlaceholder = this.getTranslationSvc.getTranslation('Código ou descrição da peça');

    if(this.hasParts)
      this.partPlaceholder = this.getTranslationSvc.getTranslation('Código ou descrição...');
  }

  confirm(
    title: string,
    message: string,
    btnOkText: string = this.getTranslationSvc.getTranslation("Sim"),
    btnCancelText: string = this.getTranslationSvc.getTranslation("Não"),
    dialogSize: 'sm' | 'sm' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  save(goToPlanning: boolean){
    if(!this.isMultipleOk()){
      this.showAmountErrorMessage();
      return;
    }

    this.setTryToPlan(goToPlanning);

    if(!this.canPlan()){
      return;
    }

    if(this.creating){
      let quotation = this.createQuotation();
      if(quotation){
        this.quotation = quotation;
      }
    }

    if(!this.quotation)
      return;

    if(!this.quotation.dealer)
      return;

    if(!this.quotation.statusQuotation)
      return;

    if(!this.isDiscountLimitOk()){
      this.showErrorMessage(`${this.translateService.instant("Existem peças com valor de desconto maior que o limite parametrizado")} (${this.commercialParameters.discountLimit}%). ${this.translateService.instant("Verifique!")}`);
      return;
    }

    let partsQuotationModel: UpInsertPartQuotationModel[] = [];
    let model: UpInsertQuotationModel = {id: this.quotation.id, dealerId: this.quotation.dealer.dealerId, userId: this.getUserId(), statusQuotationId: this.quotation.statusQuotation.id, name: this.quotation.name, partsQuotation: partsQuotationModel};
    this.partsQuotation.forEach(part => {
      model.partsQuotation.push({id: part.id, amount: part.amount, partId: part.part?.id, partCode: part.partCode, description: part.description, fixedPrice: part.fixedPrice, discountPercent: part.discountPercent, blocked: part.blocked, reasonBlocked: part.reasonBlocked, replacedToPartId: part.replacedToPart?.id, replacedMin: part.replacedMin});
    });

    this.spinnerShow();
    this.upInsertQuotation(model).subscribe(
      (success) =>{
        this.upInsertQuotationSuccess(goToPlanning, success);
      },
      (error) =>{
       this.showError(error);
      }
     );
  }

  upInsertQuotationSuccess(goToPlanning: boolean, success: any){
    this.setQuotationIdFromUpInsertQuotationSuccess(success)
    this.spinnerHide();
    this.toastr.success(this.getTranslationSvc.getTranslation('Orçamento salvo com sucesso!'), this.getTranslationSvc.getTranslation('Sucesso!'));

    if(goToPlanning){
      this.goToPlanning();
      return;
    }

    this.goToQuotationList();
  }

  removePartQuotation(partId: string){
    var replacedToPartId = this.getReplaceToPartId(partId);

    this.partsQuotation = this.partsQuotation.filter((partQuotation: PartQuotation) => partQuotation.partId !== partId);

    if (replacedToPartId !== null) {
      this.partsQuotation = this.partsQuotation.filter(part => part.replacedToPartId !== replacedToPartId);
    }

    this.dataSource = new MatTableDataSource<PartQuotation>(this.partsQuotation);
    this.calculateAllPartsProfitability();
    this.verifyHasParts();

    this.removeTotalParts();
    this.refreshGrid();
  }

  loadQuotation(){
    this.setTitle();
    this.quotationId = this.route.snapshot.paramMap.get('quotationId');
    if(!this.quotationId)
      return;

    this.creating = this.quotationId === null;

    this.spinnerShow();
    this.dataService.getQuotation(this.quotationId).subscribe(response =>{
      if(response){
        this.quotation = response.data;
        this.spinnerHide();

        if(!this.quotation){
          return;
        }

        this.setTitle();

        if(!this.quotation.partsQuotation)
          return;

        if(!this.quotation.dealer)
          return;

        this.spinnerShow();
        this.setTotalParts(this.quotation.itemsCount ?? 0);
        this.selectedDealerGroup = this.dealerGroupFilters.filter(d => d.dealerGroupId === this.quotation?.dealer?.dealerGroupId);
        this.onDealerGroupSelect(this.selectedDealerGroup[0]);
        this.selectedDealer = this.dealerFilters.filter(d => d.dealerId === this.quotation?.dealer?.dealerId);
        this.quotationName = this.quotation.name;
        this.validateFilledFields();
        this.addParts(this.quotation);
        this.statusQuotation = this.quotation.statusQuotation.name;
        this.setInBlocked();
        this.setHasPlanning();
        this.setAllLoaded(this.quotation.partsQuotation.length);
        this.spinnerHide();

        if(this.quotation.partsQuotation.length !== 0){
          return;
        }

        this.getPartsQuotationFromQuotationId();
      }
    });
  }

  onCancelClick(){
    this.confirm(this.translateService.instant("Cancelar orçamento"), this.translateService.instant("Você está prestes a cancelar o orçamento e com isso os dados incluídos/alterados nessa tela serão descartados. Deseja cancelar?"))
        .then((confirmed) => {
          if(confirmed){
            this.cancel();
          }
        });
  }

  onSaveClick(){
    this.confirm(this.translateService.instant("Salvar orçamento"), this.translateService.instant("Você está prestes a salvar o orçamento e com isso todas as alterações realizadas serão salvas. Deseja salvar?"))
        .then((confirmed) => {
          if(confirmed)
            this.save(false);
        });
  }

  onPlanClick(){
    if(this.partsQuotation.filter(p => p.blocked).length > 0){
      this.openBlockedPartsModal();
      return;
    }

    this.confirm(this.translateService.instant("Planejar pedido"), this.translateService.instant("Você está prestes a entrar no planejamento do pedido e com isso os dados incluídos/alterados nessa tela permanecerão salvos no orçamento. Deseja planejar pedido?"))
        .then((confirmed) => {
          if(confirmed)
          this.save(true);
        });
  }

  cancel(){
    this.goToQuotationList();
  }

  goToQuotationList(){
    this.router.navigate(['/quotation']);
  }

  createPlanning(){
    if(!this.quotationId){
      return;
    }
    this.router.navigate(['/createPlanning', this.quotationId]);
  }

  hasBlockedParts(){
    if(!this.blockedParts)
      return;

    if(this.blockedParts.length === 0)
      return;

    this.toastr.success(
      `<div>${this.translateService.instant('Importação/cópia concluída!')}</div><div class="margin-top10">${this.translateService.instant('Algumas peças não foram importadas.')}</div><div><u><i><b>${this.translateService.instant('Clique aqui')}</b></i></u> ${this.translateService.instant('para  visualizar as peças.')}</div><div class="margin-top10">${this.translateService.instant('Relatório completo de peças com bloqueios disponível em Consultas/Lista de peças com bloqueios.')}</div>`,
      this.translateService.instant('Sucesso!'),{
      enableHtml: true,
      closeButton: true,
      timeOut: 10000
    })
    .onTap.subscribe(tap => this.showBlockedParts());
  }

  showBlockedParts(){
    const modalRef = this.modalService.open(BlockedPartsComponent, {size: 'lg' });
    modalRef.componentInstance.partsQuotation = this.blockedParts;
  }

  setTitle(){
    let headerTitle = this.translateService.instant('Novo Orçamento');

    if(this.quotation){
      headerTitle = `${this.translateService.instant('Orçamento')}: ${this.quotation.code}`
    }

    this.setHeaderTitle.emit(headerTitle);
  }

  getPartsQuotationFromFile(){
    const formData = new FormData();

    if(this.selectedFile)
      formData.append('file', this.selectedFile, this.selectedFile.name);

    this.spinnerShow();
    this.dataService.getPartsQuotationFromFormData(formData, this.getStateAddressFromSelectedDealer()).subscribe(
      (success) =>{
        this.getPartsQuotationFromFileSuccess(success);
      },
      (error) =>{
        this.spinnerHide();
       this.showError(error);
      }
    );
  }

  getPartsQuotationFromFileSuccess(success: any){
    this.spinnerHide();

    if(!success.success)
      return;

    this.clearSelectedPart();
    this.addParts(success.data);
    this.hasBlockedParts();
  }

  copyFromQuotation(fromQuotationId: string){
    this.spinnerShow();
    this.dataService.copyFromQuotation(fromQuotationId, this.getStateAddressFromSelectedDealer()).subscribe(
      (success) =>{
        this.copyFromQuotationSuccess(success);
      },
      (error) =>{
        this.spinnerHide();
       this.showError(error);
      }
    );
  }

  copyFromQuotationSuccess(success: any){
    this.spinnerHide();
    if(!success.success)
      return;

    this.clearSelectedPart();
    this.addParts(success.data);
    this.hasBlockedParts();
  }

  getPartsQuotationFromPartId(){
    if(!this.selectedPartId)
      return;

    this.spinnerShow();

    let quotation = this.getThisQuotation();

    if(!quotation){
      this.spinnerHide();
      return;
    }

    var validPartsQuotationLength = this.validPartsQuotationLength(quotation);

    if(!validPartsQuotationLength){
      this.spinnerHide();
      this.showErrorMessage(this.translateService.instant('O orçamento não pode ter mais de 120 peças.'));
      return;
    }

    this.dataService.addPartQuotation(this.selectedPartId, quotation).subscribe(
      (success) =>{
        this.getPartsQuotationFromPartIdSuccess(success);
      },
      (error) =>{
        this.spinnerHide();
       this.showError(error);
      }
    );
  }

  getPartsQuotationFromPartIdSuccess(success: ResponseResult<Quotation>){
    this.spinnerHide();

    if(!success.success)
      return;

    this.clearSelectedPart();

    this.addParts(success.data);
    this.addTotalParts();
    this.showOneBlockedPart();
  }

  showOneBlockedPart(){
    if(!this.blockedParts){
      return;
    }

    if(this.blockedParts.length !== 1){
      return;
    }

    if(!this.blockedParts[0]){
      return;
    }

    this.removeTotalParts();
    this.toastr.error(this.blockedParts[0].reasonBlocked, this.getTranslationSvc.getTranslation('Erro'));
  }

  removeFromPartFilters(){
    if(!this.partsList)
      return;

    const partCodes: number[] = this.partsQuotation
      .map(part => [part.partCode, part.replacedToPart?.partCode])
      .flat();

    const filteredPartCodes: number[] = partCodes.filter(code => code !== null && code !== undefined);

    this.partFilters = this.partsList.filter(part => !filteredPartCodes.some(filteredPartCode => filteredPartCode === part.partCode));
  }

  createQuotation(): Quotation {
    let dealer = this.getThisDealer();
    return {
      id: '7968AC3F-CECD-4E32-A163-003321B1CD11',
      dealer: dealer,
      code: '0',
      name: this.quotationName,
      statusQuotation: {
        id: EnumQuotationStatus.Negociation,
        name: 'Negociation'},
      createdAt: null,
      partsQuotation: this.partsQuotation,
      user: null,
      canDelete: false,
      planning: null,
      createdBy: '',
      packageDiscount: this.getPackageDiscount(),
      totalWithoutDiscount: null,
      profitabilityWithoutDiscount: null,
      totalWithDiscount: null,
      profitabilityWithDiscount: null,
      totalDiscount: null,
      itemsCount: null};
  }

  upInsertQuotation(model: UpInsertQuotationModel): Observable<ResponseResult<UpInsertQuotationModel>>{
    if(this.creating)
      return this.insertQuotation(model);

    return this.updateQuotation(model);
  }

  updateQuotation(model: UpInsertQuotationModel): Observable<ResponseResult<UpInsertQuotationModel>>{
    return this.dataService.updateQuotation(model);
  }

  insertQuotation(model: UpInsertQuotationModel): Observable<ResponseResult<UpInsertQuotationModel>>{
    return this.dataService.insertQuotation(model);
  }

  getUserId(): string {
    let key = <any>localStorage.getItem('TokenClaims');
    let token = <any>localStorage.getItem(key.toString());
    return JSON.parse(token).idTokenClaims.oid.toString();
  }

  setInBlocked(){
    this.blocked = false;

    if(!this.quotation)
      return;

    if(!this.quotation.statusQuotation)
      return;

    if(!this.quotation.planning)
      return;

    if(!this.quotation.planning.status)
      return;

    if(this.quotation.statusQuotation.id.toLowerCase() == EnumQuotationStatus.Negociation.toLowerCase())
      return;

    if(this.quotation.statusQuotation.id.toLowerCase() == EnumQuotationStatus.Planning.toLowerCase() &&
      this.quotation.planning.status.id.toLowerCase() == EnumPlanningStatus.Registered.toLowerCase())
      return;

    this.blocked = true;
  }

  openWarningsModal(warningsPart: WarningPart[]){
    const modalRef = this.modalService.open(WarningsPartComponent, {size: 'sm' });
    modalRef.componentInstance.warningsPart = warningsPart;
    modalRef.componentInstance.titleText = this.translateService.instant('Atenção');
    modalRef.componentInstance.messageText = this.translateService.instant('Esta peça possui as seguintes restrições:');
  }

  showWarning(warningsPart: WarningPart[]) : boolean{
    if(!warningsPart)
      return false;

    if(warningsPart.length == 0)
      return false;

    return true;
  }

  warningText(warningsPart: WarningPart[]): string {
    if(this.showWarning(warningsPart))
      return 'text-color-warning'

      return '';
  }

  onPartDropdownClose(){
    this.getParts('');
    this.checkDuplicatePart('');
  }

  isSameSearchTerm(searchTerm: string) : boolean {
    if(!this.filterObject) {
      return false;
    }

    if(this.filterObject.searchKey === null) {
      return false;
    }

    if(this.filterObject.searchKey === undefined) {
      return false;
    }

    if(this.filterObject.searchKey !== searchTerm){
      return false;
    }

    return true;
  }

  openOtherPlanningsModal(planningsWithPart: PlanningHasPart[]){
    const modalRef = this.modalService.open(PartOtherPlanningsComponent, {size: 'lg' });
    modalRef.componentInstance.loadPlannings(planningsWithPart);
  }

  showOtherPlannings(otherPlannings: PlanningHasPart[]) : boolean{
    if(!otherPlannings)
      return false;

    if(otherPlannings.length == 0)
      return false;

    return true;
  }

  setQuotationIdFromUpInsertQuotationSuccess(success: any) {
    if(!success){
      return;
    }

    if(!success.data){
      return;
    }

    if(!success.data.id){
      return;
    }

    this.quotationId = success.data.id.toString();
  }

  setDisabledInputs(partQuotation: PartQuotation){
    if(this.applyPackageDiscount){
      partQuotation.discountPercentDisabled = true;
      partQuotation.fixedPriceDisabled = true;
      return;
    }

    partQuotation.discountPercentDisabled = false;
    partQuotation.fixedPriceDisabled = false;

    if(partQuotation.fixedPrice > 0){
      partQuotation.discountPercentDisabled = true;
    }

    if(partQuotation.discountPercent > 0){
      partQuotation.fixedPriceDisabled = true;
    }
  }

  showError(error: any){
    this.spinnerHide();
    if(error.error.data){
      this.showErrorMessage(error.error.data.toString());
      return;
    }

    if(error.error.title){
      this.showErrorMessage(error.error.title.toString());
      return;
    }

    if(error.error.message){
      this.showErrorMessage(error.error.message.toString());
      return;
    }

    this.showErrorMessage('Um erro ocorreu.');
  }

  getStateAddressFromSelectedDealer() : string{
    if(!this.selectedDealer){
      return '';
    }

    if(!this.selectedDealer[0]){
      return '';
    }

    let dealers = this.dealerList.filter(d => d.dealerId === this.selectedDealer[0].dealerId);

    if(!dealers){
      return '';
    }

    if(!dealers[0]){
      return '';
    }
    return dealers[0].stateAddress;
  }

  calculateProfitabilityOnChangeValue(rowIndex: number) {
    const partsQuotation: PartQuotation[] = [this.partsQuotation[rowIndex]];
    this.calculateProfitability(partsQuotation);
  }

  calculateProfitability(partsQuotation: PartQuotation[]){
    this.updateQuotationDealer();

    if(!partsQuotation){
      return;
    }

    if(partsQuotation.length === 0){
      return;
    }

    let stateAddress = this.getStateAddressFromSelectedDealer();

    if(!stateAddress){
      this.clearProfitability(partsQuotation);
      return;
    }

    if(stateAddress == ''){
      this.clearProfitability(partsQuotation);
      return;
    }

    if(!this.quotation){
      this.quotation = this.getThisQuotation();
    }

    if(!this.quotation){
      return;
    }

    this.quotation.partsQuotation = this.partsQuotation;

    this.dataService.calculateProfitability(this.quotation).subscribe(
      (success) =>{
        this.calculateProfitabilitySuccess(success);
      },
      (error) =>{
        this.showError(error);
      }
     );
  }

  calculateProfitabilitySuccess(success: ResponseResult<Quotation>){
    if(!success.data){
      return;
    }

    if(!success.data.partsQuotation){
      return;
    }

    const partsQuotation = success.data.partsQuotation;

    partsQuotation.forEach(p => {
      let partQuotation = this.partsQuotation.find(part => part.partCode === p.partCode);
      if(partQuotation){
        partQuotation.priceWithoutDiscount = p.priceWithoutDiscount;
        partQuotation.priceWithDiscount = p.priceWithDiscount;
        partQuotation.profitabilityWithoutDiscount = p.profitabilityWithoutDiscount;
        partQuotation.profitabilityWithDiscount = p.profitabilityWithDiscount;
      }
    });

    this.setSummary(success.data);
  }

  calculateAllPartsProfitability(){
    this.calculateProfitability(this.partsQuotation);
  }

  clearProfitability(partsQuotation: PartQuotation[]){
    partsQuotation.forEach(p => {
      let partQuotation = this.partsQuotation.find(part => part.partCode === p.partCode);
      if(partQuotation){
        partQuotation.priceWithoutDiscount = 0;
        partQuotation.priceWithDiscount = 0;
        partQuotation.profitabilityWithoutDiscount = 0;
        partQuotation.profitabilityWithDiscount = 0;
      }
    });
  }

  getThisQuotation(): Quotation{
    if(!this.quotation){
      return this.createQuotation();
    }

    this.quotation.packageDiscount = 0;

    if(this.applyPackageDiscount){
      this.quotation.packageDiscount = this.discountPackage;
    }

    this.quotation.partsQuotation = this.partsQuotation;
    return this.quotation;
  }

  getThisDealer(): Dealer | null{
    if(!this.selectedDealer){
      return null;
    }

    if(!this.selectedDealer[0]){
      return null;
    }

    let dealers = this.dealerList.filter(d => d.dealerId === this.selectedDealer[0].dealerId);

    if(!dealers){
      return null;
    }

    if(!dealers[0]){
      return null;
    }

    return dealers[0];
  }

  getPackageDiscount(): number{
    if(!this.applyPackageDiscount){
      return 0;
    }

    return this.discountPackage;
  }

  setSummary(quotation: Quotation){
    if(!quotation){
      return;
    }

    if(!this.quotation){
      this.quotation = this.createQuotation();
    }

    if(quotation.totalWithDiscount === 0){
      this.quotation.totalWithoutDiscount = 0;
      this.quotation.profitabilityWithoutDiscount = 0;
      this.quotation.totalWithDiscount = 0;
      this.quotation.profitabilityWithDiscount = 0;
      this.quotation.totalDiscount = 0;
      return;
    }

    this.quotation.totalWithoutDiscount = quotation.totalWithoutDiscount;
    this.quotation.profitabilityWithoutDiscount = quotation.profitabilityWithoutDiscount;
    this.quotation.totalWithDiscount = quotation.totalWithDiscount;
    this.quotation.profitabilityWithDiscount = quotation.profitabilityWithDiscount;
    this.quotation.totalDiscount = quotation.totalDiscount;
  }

  getCommercialParameters(){
    this.commercialParametersService.getCommercialParameters().subscribe(response =>{
      this.spinnerShow();
       this.commercialParameters = response.data;
       this.spinnerHide();
    });
  }

  isDiscountLimitOk(): boolean{
    if(!this.commercialParameters){
      return false;
    }

    if(!this.commercialParameters.discountlimitActive){
      return false;
    }

    for (const part of this.partsQuotation) {
      if(part.discountPercent > this.commercialParameters.discountLimit){
        return false;
      }
    }

    return true;
  }

  showErrorMessage(error: string){
    this.toastr.error(this.getTranslationSvc.getTranslation(error), this.getTranslationSvc.getTranslation('Erro'));
  }

  invalidDiscountValue(discountValue: number): boolean{
    if(!this.commercialParameters){
      return false;
    }

    if(this.commercialParameters.discountLimit === 0){
      return false;
    }

    if (discountValue <= this.commercialParameters.discountLimit) {
      return false;
    }

    return true;
  }

  invalidProfitabilityValue(profitabilityValue: number){
    if(!this.commercialParameters){
      return false;
    }

    if(!this.commercialParameters.profitability){
      return false;
    }

    if (profitabilityValue >= this.commercialParameters.profitability) {
      return false;
    }

    return true;
  }

  openKitList(kitCodes: string[]){
    const modalRef = this.modalService.open(KitListComponent, {size: 'sm' });
    modalRef.componentInstance.kitCodes = kitCodes;
    modalRef.componentInstance.titleText = this.translateService.instant('Atenção');
    modalRef.componentInstance.messageText = this.translateService.instant('Esta peça faz parte dos seguintes kits:');
  }

  isPriceOk(): boolean{
    for (const part of this.partsQuotation) {
      if(!this.isPartPriceOk(part)){
        return false;
      }
    }

    return true;
  }

  isPartPriceOk(part: PartQuotation): boolean{
    if(!this.tryToPlan){
      return true;
    }

    if((!part.fixedPrice) && (!part.discountPercent)){
      return false;
    }

    if((!part.fixedPrice) && part.discountPercent <= 0){
      return false;
    }

    if(part.fixedPrice <= 0 && (!part.discountPercent)){
      return false;
    }

    if(part.fixedPrice <= 0 && part.discountPercent <= 0){
      return false;
    }

    return true;
  }

  isAmountOk(): boolean{
    for (const part of this.partsQuotation) {
      if(!this.isPartAmountOk(part)){
        return false;
      }
    }

    return true;
  }

  isPartAmountOk(part: PartQuotation): boolean{
    if(!this.isPartMultipleOk(part)){
      return false;
    }

    this.clearAmountMessage(part);

    if(!this.tryToPlan){
      return true;
    }

    if(!part.amount){
      this.setAmountMessage(part);
      return false;
    }

    if(part.amount <= 0){
      this.setAmountMessage(part);
      return false;
    }

    return true;
  }

  canPlan(): boolean{
    if(!this.tryToPlan){
      return true;
    }

    if(!this.isPriceOk()){
      this.showErrorMessage('Existem peças sem preço. Verifique!');
      return false;
    }

    if(!this.isAmountOk()){
      this.showAmountErrorMessage();
      return false;
    }

    return true;
  }

  setTryToPlan(value: boolean){
    this.tryToPlan = value;
  }

  setHasPlanning(){
    this.hasPlanning = false;

    if(!this.quotation){
      return;
    }

    if(!this.quotation.planning){
      return;
    }

    this.hasPlanning = true;
  }

  onGoToPlanningClick(){

    if(!this.quotation){
      return;
    }

    if(!this.quotation.planning){
      return;
    }

    if(!this.quotation.planning.status){
      return;
    }

    if(this.quotation.planning.status.id.toLowerCase() === EnumPlanningStatus.Transmitted.toLowerCase()){
      this.goToTransmittedPlanning(this.quotation.planning.id);
      return;
    }

    const modalRef = this.modalService.open(PlanningDialogComponent, {size: 'md' });
    modalRef.componentInstance.goToPlanning = this.goToPlanning.bind(this);
    modalRef.componentInstance.saveAndGoToPlanning = this.save.bind(this);
  }

  goToTransmittedPlanning(planningId: string){
    if(!planningId){
      return;
    }

    this.confirm(this.translateService.instant("Atenção"), this.translateService.instant("Você está prestes a sair do orçamento e acessar o planejamento correspondente. Confirma?"))
        .then((confirmed) => {
          if(confirmed){
            this.router.navigate(['../editPlanning', planningId]);
          }
        });
  }

  goToPlanning(){
    if(!this.quotation){
      this.createPlanning();
      return;
    }

    if(!this.quotation.planning){
      this.createPlanning();
      return;
    }

    this.router.navigate(['../editPlanning', this.quotation.planning.id]);
  }

  checkDuplicatePart(partNumber: string){
    this.showDuplicatePartMessage = false;

    if(!partNumber){
      return;
    }

    if(partNumber === ''){
      return;
    }

    if(isNaN(Number(partNumber))){
      return;
    }

    if(!this.partsQuotation){
      return;
    }

    if(this.partsQuotation.length === 0){
      return;
    }

    let part = this.partsQuotation.filter(p => p.partCode.toString() === partNumber);

    if(!part){
      return;
    }

    if(part.length === 0){
      return;
    }

    this.duplicatePartMessage = `${this.translateService.instant('A peça com o código')} ${partNumber} ${this.translateService.instant('já existe no orçamento')}`;
    this.showDuplicatePartMessage = true;
  }

  getCurrentPromotionWidth(){
    if(!this.partsQuotation){
      return 'width-150';
    }

    if(this.partsQuotation.some(p => p.currentPromotion !== null)){
      return 'width-250';
    }

    return 'width-150';
  }


  verifyReplacedValue(rowIndex: number){
    let partQuotation: PartQuotation = this.partsQuotation[rowIndex];

    if(!this.isPartQuotationValidToCheckMultipleAmount(partQuotation)){
      return;
    }

    if(partQuotation.amount === null){
      partQuotation.amount = partQuotation.replacedMin;
    }

    if(!this.verifyMultiple(partQuotation)){
      this.setMessage(partQuotation, this.multipleMessage);
      return;
    }

    var otherReplacedParts = this.partsQuotation.filter(x => x.replacedToPartId === partQuotation.replacedToPartId && x.partId !== partQuotation.partId);

    if(!otherReplacedParts){
      return;
    }

    otherReplacedParts.forEach(x => this.verifyProportion(x, partQuotation));
  }

  verifyProportion(otherPart: PartQuotation, partQuotation: PartQuotation){
    if(!otherPart.replacedMin){
      return;
    }

    if(otherPart.replacedMin === 0){
      return;
    }

    var modifier = otherPart.replacedMin / partQuotation.replacedMin;

    otherPart.amount = partQuotation.amount * modifier;
  }

  verifyMultiple(partQuotation: PartQuotation): boolean{

    if(!this.isPartQuotationValidToCheckMultipleAmount(partQuotation)){
      return true;
    }

    if(partQuotation.amount === 0){
      return false;
    }

    if(!this.isMultiple(partQuotation.amount, partQuotation.replacedMin)){
      return false;
    }

    return true;
  }

  isMultiple(number: number, multipleOf: number): boolean {
    return number % multipleOf === 0;
  }

  isPartQuotationValidToCheckMultipleAmount(partQuotation: PartQuotation): boolean {
    if(!partQuotation){
      return false;
    }

    if(!partQuotation.replacedToPartId){
      return false;
    }

    if(partQuotation.replacedToPartId === ''){
      return false;
    }

    if(!partQuotation.replacedMin){
      return false;
    }

    if(partQuotation.replacedMin === 0){
      return false;
    }

    return true;
  }

  setMultipleMessage(part: PartQuotation){
    this.setMessage(part, `${this.multipleMessage} ${part?.replacedMin}`);
  }

  setAmountMessage(part: PartQuotation){
    this.setMessage(part, this.amountMessage);
  }

  setMessage(part: PartQuotation, message: string){
    part.errorMessage = message;
  }

  clearMultipleMessage(part: PartQuotation){
    this.clearMessage(part, this.multipleMessage);
  }

  clearAmountMessage(part: PartQuotation){
    this.clearMessage(part, this.amountMessage);
  }

  clearMessage(part: PartQuotation, message: string){
    if(!part){
      return;
    }

    if(!part.errorMessage){
      return;
    }

    if(!part.errorMessage.includes(message)){
      return;
    }

    part.errorMessage = '';
  }

  isMultipleOk(): boolean{
    for (const part of this.partsQuotation) {
      if(!this.isPartMultipleOk(part)){
        return false;
      }
    }

    return true;
  }

  isPartMultipleOk(part: PartQuotation): boolean{
    this.clearMultipleMessage(part);

    if(!this.verifyMultiple(part)){
      this.setMultipleMessage(part);
      return false;
    }

    return true;
  }

  showAmountErrorMessage(){
    this.showErrorMessage('Existem peças sem quantidade ou com quantidade incorreta. Verifique!');
  }

  updateQuotationDealer(){
    if(!this.selectedDealer){
      return;
    }

    if(this.selectedDealer.length === 0){
      return;
    }

    if(!this.quotation){
      return;
    }

    this.quotation.dealer = this.getThisDealer();
  }

  setAmount(part: PartQuotation){
    if(!part){
      return;
    }

    if(part.amount){
      return;
    }

    if(part.amount === 0){
      return;
    }

    part.amount = 0;

    if(!part.replacedMin){
      return;
    }

    if(part.replacedMin === 0){
      return;
    }

    part.amount = part.replacedMin;
  }

  clickExportQuotationToCsv(){
    if(!this.partsQuotation){
      this.showNoPartsErrorMessage();
      return;
    }

    if(this.partsQuotation.length <= 0){
      this.showNoPartsErrorMessage();
      return;
    }

    if(!this.quotation){
      this.showNotSavedErrorMessage();
      return;
    }

    if(!this.quotation.code){
      this.showNotSavedErrorMessage();
      return;
    }

    if(this.quotation.code === '0'){
      this.showNotSavedErrorMessage();
      return;
    }

    this.confirm(this.translateService.instant("Confirmar"), this.translateService.instant("Deseja fazer download do arquivo .csv?"))
      .then((confirmed) => {
        if(confirmed){
          this.exportQuotationToCsv();
        }
      });
  }

  exportQuotationToCsv(){
    this.dataService.exportQuotationToCsv({partsQuotation: this.partsQuotation, code: this.quotation.code}).subscribe(
      response => {
        let fileName = this.parseHeaders(response.headers)
        this.dataService.downloadFile(response.body, 'text/csv', fileName);
      }, error => {
        this.toastr.error(this.translateService.instant("Problema ao tentar fazer download do relatório"), this.translateService.instant('Erro'), { timeOut: 3000, extendedTimeOut: 3000 });
      });
  }

  showNoPartsErrorMessage(){
    this.showErrorMessage('Não há peças no orçamento!');
  }

  showNotSavedErrorMessage(){
    this.showErrorMessage('Você deve salvar o orçamento primeiro para poder exportar!');
  }

  parseHeaders(responseHeader: any) {
		let contentDisposition = responseHeader.get('content-disposition');
		return contentDisposition.split(';')[1].split('=')[1];
	}

  getThisDealerName(): string{
    let dealer = this.getThisDealer();
    if(dealer){
      return dealer.corporateName;
    }

    return '';
  }

  isDealerStateValid(): boolean{
    let dealer = this.getThisDealer();
    if(!dealer){
      return true;
    }

    if(!dealer.stateAddress){
      return false;
    }

    if(dealer.stateAddress === ''){
      return false;
    }

    return true;
  }

  showBlockedWarning(blocked: boolean | null) : boolean{
    if(!blocked)
      return false;

    return blocked;
  }

  openBlockedWarningsModal(reasonBlocked: string){
    this.openMessageListModal([reasonBlocked], this.translateService.instant('Atenção'), this.translateService.instant('Peça com bloqueio:'), 'sm');
  }

  openMessageListModal(messageList: string[], titleText: string, messageText: string, modalSize: string){
    const modalRef = this.modalService.open(MessageListModalComponent, {size: modalSize });
    modalRef.componentInstance.messageList = messageList;
    modalRef.componentInstance.titleText = titleText;
    modalRef.componentInstance.messageText = messageText;
  }

  openBlockedPartsModal(){
    let partsQuotationBlocked: PartQuotation[] = this.partsQuotation.filter(p => p.blocked);
    let messages: string[] = partsQuotationBlocked
      .map(part => `${part.partCode} - ${part.description} - ${this.translateService.instant('Motivo do bloqueio:')} ${part.reasonBlocked}`);
    this.openMessageListModal(messages, this.translateService.instant('Atenção'), this.translateService.instant('Não é possível planejar um orçamento com peças bloqueadas.\nRemova as peças abaixo:'), 'xl');
  }

  getPartsQuotationFromQuotationId(){
    this.loadingParts = true;
    this.dataService.getPartsQuotationFromQuotationId(this.quotationId, this.page, this.limit).subscribe(response =>{
      if(response){
        var responseData = response.data;
        var partsQuotation = responseData.data;

        if(this.page === this.firstPage){
          this.partsQuotation = [];
        }

        this.partsQuotation.push(...partsQuotation);
        this.quotation.partsQuotation = this.partsQuotation;
        this.addParts(this.quotation);
        this.statusQuotation = this.quotation.statusQuotation.name;
        this.setInBlocked();
        this.setHasPlanning();
        this.loadingParts = false;
        this.setTotalParts(response.data.total);
        this.setAllLoaded(partsQuotation.length);
      }
    });
  }

  getNextPagePartsQuotation(){

    if(this.loadingParts){
      return;
    }

    if(this.allLoaded){
      return;
    }

    this.page++;
    this.getPartsQuotationFromQuotationId();
  }

  spinnerShow(){
    this.spinnerCount++;
    this.spinner.show();
  }

  spinnerHide(){
    this.spinnerCount--;
    if(this.spinnerCount > 0){
      return;
    }
    this.spinner.hide();
  }

  onDivScroll() {
    if(this.creating)
      return;

    const div = this.scrollableDiv.nativeElement;
    const atBottom = div.scrollHeight - div.scrollTop <= div.clientHeight;

    if (atBottom) {
      this.getNextPagePartsQuotation();
    }
  }

  setAllLoaded(results: number){
    this.allLoaded = (results < this.limit) || (this.totalParts === this.partsQuotation.length);
  }

  getTranslate(key: string): string{
    return this.translateService.instant(key);
  }

  getReplaceToPartId(partId: string): string | null {
    const item = this.partsQuotation.find(part => part.partId === partId);
    return item ? item.replacedToPartId : null;
  }

  validPartsQuotationLength(quotation: Quotation): boolean{
    const limit = 120;
    return !(this.quotationPartsQuotationBiggerThan(quotation, limit) ||
      this.thisPartsQuotationBiggerThan(limit) ||
      this.thisTotalPartsBiggerThan(limit));
  }

  quotationPartsQuotationBiggerThan(quotation: Quotation, limit: number): boolean{
    if(!quotation){
      return false;
    }

    if(!quotation.partsQuotation){
      return false;
    }

    return this.partsQuotationBiggerThan(quotation.partsQuotation, limit);
  }

  thisPartsQuotationBiggerThan(limit: number): boolean{
    if(!this.partsQuotation){
      return false;
    }

    return this.partsQuotationBiggerThan(this.partsQuotation, limit);
  }

  partsQuotationBiggerThan(partsQuotation: PartQuotation[], limit: number): boolean{
    if(!partsQuotation){
      return false;
    }

    return this.numberBiggerThan(partsQuotation.length, limit);
  }

  numberBiggerThan(number: number, limit: number): boolean{
    return number >= limit;
  }

  thisTotalPartsBiggerThan(limit: number): boolean{
    if(!this.totalParts){
      return false;
    }

    return this.numberBiggerThan(this.totalParts, limit);
  }

  addTotalParts(){
    if(!this.totalParts){
      this.totalParts = 0;
    }

    this.totalParts++;
  }

  removeTotalParts(){
    if(!this.totalParts){
      this.totalParts = 0;
    }

    if(this.totalParts > 0){
      this.totalParts--;
    }
  }

  setTotalParts(totalParts: number){
    if(!this.totalParts){
      this.totalParts = 0;
    }

    if(this.totalParts > 0){
      return;
    }

    this.totalParts = totalParts;
  }
}
