import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "src/app/services/base.service";
import { GetTranslationService } from "src/app/shared/utils/get-translation.service";
import { InconsistencyPlanning, PartPlanning, Planning, UpInsertPlanningModel } from "../models/planning";
import { PagedResponse, ResponseResult } from "src/app/services/grid-data-source.service";
import { Observable, catchError, map } from "rxjs";
import { environment } from "src/environments/environment";
import { Quotation } from "src/app/shared/models/quotation";

@Injectable({
  providedIn: 'root'
})

export class PlanningService extends BaseService {

  constructor(private http: HttpClient,
    public getTranslationSvc: GetTranslationService) {
    super();
  }

  getAllPlanning(filterObject:any): Observable<PagedResponse<Planning>> {
    return this.http
    .get<PagedResponse<Planning>>(`${environment.URL_API_PARTS_ORDER_BOOK}Planning/`, {params: filterObject, headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  deletePlanning(id: string, deleteQuotation: boolean): Observable<ResponseResult<Planning>> {
    return this.http
      .delete<ResponseResult<Planning>>(`${environment.URL_API_PARTS_ORDER_BOOK}Planning/${id}/${deleteQuotation}`, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  getAllQuotation(filterObject:any): Observable<PagedResponse<Quotation>> {

    return this.http
    .get<PagedResponse<Quotation>>(`${environment.URL_API_PARTS_ORDER_BOOK}Quotation/${filterObject.page}/${filterObject.limit}`, {params: filterObject, headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  getNewPlanning(quotationId: string): Observable<ResponseResult<Planning>> {

    return this.http
    .get<ResponseResult<Planning>>(`${environment.URL_API_PARTS_ORDER_BOOK}Planning/new/${quotationId}`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  getPlanning(planningId: string): Observable<ResponseResult<Planning>> {

    return this.http
    .get<ResponseResult<Planning>>(`${environment.URL_API_PARTS_ORDER_BOOK}Planning/${planningId}`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  updatePlanning(model: UpInsertPlanningModel): Observable<ResponseResult<UpInsertPlanningModel>> {
    return this.http
      .put<ResponseResult<UpInsertPlanningModel>>(`${environment.URL_API_PARTS_ORDER_BOOK}Planning`, model, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  insertPlanning(model: UpInsertPlanningModel): Observable<ResponseResult<UpInsertPlanningModel>> {
    return this.http
      .post<ResponseResult<UpInsertPlanningModel>>(`${environment.URL_API_PARTS_ORDER_BOOK}Planning`, model, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  sendPlanning(planningId: string): Observable<ResponseResult<UpInsertPlanningModel>> {
    return this.http
      .post<ResponseResult<UpInsertPlanningModel>>(`${environment.URL_API_PARTS_ORDER_BOOK}Planning/SendPlanning/${planningId}`, null, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  getInconsistencies(planningId: string): Observable<ResponseResult<InconsistencyPlanning[]>> {
    return this.http
    .get<ResponseResult<InconsistencyPlanning>>(`${environment.URL_API_PARTS_ORDER_BOOK}Planning/inconsistency/${planningId}`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  putInconsistency(model: InconsistencyPlanning): Observable<ResponseResult<InconsistencyPlanning>> {
    return this.http
      .put<ResponseResult<InconsistencyPlanning>>(`${environment.URL_API_PARTS_ORDER_BOOK}Planning/inconsistency/${model.id}/${model.warningActive}`, model, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  completePlanning(planningId: string): Observable<ResponseResult<string>> {
    return this.http
      .put<ResponseResult<string>>(`${environment.URL_API_PARTS_ORDER_BOOK}Planning/complete/${planningId}`, null, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  downloadReport(planningId: string, sortField: string, sortDirection: string) {
    const headers = {
      'Content-Type': 'application/json',
      'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`,
      'Authorization': `Bearer ${this.LocalStorage.getUserToken()}`,
    }
    return this.http
      .get(`${environment.URL_API_PARTS_ORDER_BOOK}Planning/Download/${planningId}/${sortField}/${sortDirection}`, {headers: super.GetAuthTokenHeader().headers, responseType: 'arraybuffer', observe: 'response'})
  }

  downloadFile(data: any, type: string, fileName: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  getPartsPlanningFromPlanningId(fromPlanningId: string, page: number, limit: number): Observable<PagedResponse<PartPlanning>> {
    return this.http
    .get<PagedResponse<PartPlanning>>(`${environment.URL_API_PARTS_ORDER_BOOK}PartPlanning/${fromPlanningId}/${page}/${limit}`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }
}
