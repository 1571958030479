import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlanningService } from '../../services/planning.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { InconsistencyPlanning, PartPlanning, Planning, UpInsertPartPlanningModel, UpInsertPlanningModel, UpInsertWeekPartPlanningModel, WeekColumns, WeekPartPlanning } from '../../models/planning';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { GridDataSource, ResponseResult } from 'src/app/services/grid-data-source.service';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe, DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { EnumStatusWeekPartPlanning } from 'src/app/shared/models/enumStatusWeekPartPlanning';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { EnumPlanningStatus } from 'src/app/shared/models/enumPlanningStatus';
import { WarningPart } from 'src/app/shared/models/warningPart';
import { WarningsPartComponent } from 'src/app/shared/component/warnings-part/warnings-part.component';
import { PartOtherPlanningsComponent } from '../../../../shared/component/part-other-plannings/part-other-plannings.component';
import { PartDetailsComponent } from '../part-details/part-details.component';
import { PartsInformationComponent } from '../parts-information/parts-information.component';
import { PlanningInconsistenciesComponent } from '../planning-inconsistencies/planning-inconsistencies.component';
import { QuotationDialogComponent } from '../quotation-dialog/quotation-dialog.component';
import { PlanningHasPart } from 'src/app/shared/models/planningHasPart';
import { DeletePlanningComponent } from '../delete-planning/delete-planning.component';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { environment } from 'src/environments/environment';
import { isEqual } from 'lodash';
import { PartQuotation } from 'src/app/shared/models/partQuotation';
import { stringToBool } from 'src/app/shared/utils/booleansUtil';

@Component({
  selector: 'app-create-planning',
  templateUrl: './create-planning.component.html',
  styleUrls: ['./create-planning.component.css'],
  providers: [GridDataSource, DecimalPipe]
})
export class CreatePlanningComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort;
  @Output() clickMenuItem = new EventEmitter<string>();

  @ViewChild('fillerPlanningGrid', { static: false }) fillerPlanningGrid!: ElementRef;
  @ViewChild('divPlanningGrid', { static: false }) divPlanningGrid!: ElementRef;
  @ViewChild('planningGrid', { static: false }) planningGrid!: ElementRef;
  @ViewChild('divCardPrincipal', { static: false }) divCardPrincipal!: ElementRef;

  quotationId: string | null = null;
  planningId: string | null = null;
  displayedColumns: string[] = ['partAlert', 'partCode', 'description', 'quotationQuantity', 'planningQuantity'];
  partsPlanning: PartPlanning[] = [];
  planning!: Planning;
  dataSource = new MatTableDataSource<PartPlanning>(this.partsPlanning);
  filterObject: any = {};
  weekColumns: WeekColumns[] = [];
  creating: boolean = true;
  edited: boolean = false;
  headerTitle!: string;
  inconsistencies: InconsistencyPlanning[] = [];
  numberOfInconsistencies: string = '';
  datePipe: DatePipe = new DatePipe('en-US');
  public LocalStorage = new LocalStorageUtils();
  canAccess = false;
  loadedModel!: UpInsertPlanningModel;
  sortState!: Sort;
  defaultSortStateActive: string = 'partCode'
  defaultSortStateDirection: string = 'asc'
  page: number = 1;
  firstPage: number = 1;
  limit: number = 100;
  totalParts: number = 0;
  loadingParts: boolean = false;
  allLoaded: boolean = false;
  totalPercentage: number = 100;
  gridPercentageHeight: number = 50;
  gridLineHeight: number = 52;
  gridHeaderHeight: number = 73;

  constructor(private route: ActivatedRoute,
    public dataService: PlanningService,
    private spinner: NgxSpinnerService,
    public service: GridDataSource<PartPlanning>,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private router:Router,
    private toastr: ToastrService,
    private elementRef: ElementRef) {
      this.setCanAccess();
  }

  ngOnInit() {
    this.clickMenuItem.emit('planning');

    this.quotationId = this.route.snapshot.paramMap.get('quotationId');
    this.planningId = this.route.snapshot.paramMap.get('planningId');

    this.creating = this.planningId === null;

    if(this.quotationId){
      this.getPlanningFromQuotationId(this.quotationId);
    }

    if(this.planningId){
      this.getPlanningFromPlanningId(this.planningId);
    }
  }

  getPlanningFromQuotationId(quotationId: string){
    if(!quotationId)
      return;

    this.spinner.show();
    this.dataService.getNewPlanning(quotationId).subscribe(
      response => {
        this.spinner.hide();
        this.headerTitle = this.translateService.instant('Novo Planejamento');

        if(!response)
          return;

        if(!response.data)
          return;

        this.planning = response.data;

        if(!response.data.partsPlanning)
          return;

        this.partsPlanning = response.data.partsPlanning;

        this.dataSource = new MatTableDataSource<PartPlanning>(this.partsPlanning);

        this.dynamicColumns();
      });
  }

  getPlanningFromPlanningId(planningId: string){
    if(!planningId)
      return;

    this.spinner.show();
    this.dataService.getPlanning(planningId).subscribe(
      response => {
        this.spinner.hide();

        if(!response)
          return;

        if(!response.data)
          return;

        this.planning = response.data;
        this.headerTitle = `${this.translateService.instant('Planejamento')}: ${this.planning.code}`;

        if(!response.data.partsPlanning)
          return;

        this.partsPlanning = response.data.partsPlanning;
        this.limit = this.partsPlanning.length;

        this.dataSource = new MatTableDataSource<PartPlanning>(this.partsPlanning);

        this.totalParts = this.planning.itemsCount ?? 0;

        this.dynamicColumns();
        this.calculatePlanningQuantity();
        this.getInconsistencies();
        this.setLoadedModel();
        this.setAllLoaded(this.planning.partsPlanning.length);
      });
  }

  announceSortChange(sortState: any) {
    this.sortState = sortState;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case this.defaultSortStateActive: return item.partQuotation.part.partCode;
        case 'description': return item.partQuotation.part.description;
        case 'quotationQuantity': return item.partQuotation.amount;
        case 'planningQuantity': return item.amount as number;
        default: return '';
      }
    };
    this.dataSource.sort = this.sort;
  }

  onPageChange(event: any) {
    this.filterObject.page = event.pageIndex+1
    this.refreshGrid();
  }

  refreshGrid(){
    this.filterObject.limit = this.paginator.pageSize;
    // this.setCanDelete();
    this.service.refresh();
  }

  dynamicColumns(){
    this.weekColumns = [];

    this.partsPlanning.forEach(partPlanning => {
      let orderedWeeks = partPlanning.weeksPartPlanning.sort(this.compareWeek);
      orderedWeeks.forEach(weekPartPlanning => {
        let yearWeek = (weekPartPlanning.year * 100) + weekPartPlanning.week;
        if(!this.weekColumns.some(w => w.yearWeek === yearWeek)){
          this.weekColumns.push({year: weekPartPlanning.year, week: weekPartPlanning.week, yearWeek: yearWeek, amountColumnName: this.getAmountColumnName(weekPartPlanning), dateColumnName: this.getDateColumnName(weekPartPlanning), amountColumnDef: this.getAmountColumnDef(yearWeek), dateColumnDef: this.getDateColumnDef(yearWeek)});
          this.displayedColumns.push(this.getAmountColumnDef(yearWeek));
          this.displayedColumns.push(this.getDateColumnDef(yearWeek));
        }
      });
    });

    this.weekColumns = this.weekColumns.sort((a, b) => a.yearWeek - b.yearWeek);

    this.setSizes();
  }

  getAmountColumnName(weekPartPlanning: WeekPartPlanning){
    return weekPartPlanning.year.toString() + " W" + weekPartPlanning.week.toString();
  }

  getDateColumnName(weekPartPlanning: WeekPartPlanning){
    return this.translateService.instant('Dt. Lib W') + weekPartPlanning.week.toString();
  }

  getAmountColumnDef(yearWeek: Number){
    return yearWeek.toString() + "Amount";
  }

  getDateColumnDef(yearWeek: Number){
    return yearWeek.toString() + "Date";
  }

  onInputChange(event: Event, rowIndex: number, columnIndex: number) {
    this.edited = true;
    const inputValue = (event.target as HTMLInputElement).value;
    const sanitizedValue = inputValue.split('.')[0].replace(/[^\d]/g, '');
    const numberSanitized = Number(sanitizedValue)
    this.planning.partsPlanning[rowIndex].weeksPartPlanning[columnIndex].amount = numberSanitized;
    this.totalizePlanningAmout(this.planning.partsPlanning[rowIndex]);
    this.verifyReplacedValue(rowIndex, columnIndex);
  }

  inputReleaseDate(type: string, event: MatDatepickerInputEvent<Date>){
    this.edited = true;
  }

  getStatusBorder(week: WeekPartPlanning): string{

    let defaultBorder = '1px solid gray';

    if(!week){
      return defaultBorder;
    }

    if(this.hasMultipleAmountError(week.multipleAmountError)) {
      return '2px solid red';
    }

    if(!week.status){
      return defaultBorder;
    }

    let status = week.status.id;

    if (status.toLowerCase() === EnumStatusWeekPartPlanning.Current.toLowerCase()) {
      return '2px solid rgb(181,224,188)';
    } else if (status.toLowerCase() === EnumStatusWeekPartPlanning.Firm.toLowerCase()) {
      return '2px solid rgb(255,160,0)';
    } else {
      return defaultBorder;
    }
  }

  onCancelClick(){
    this.confirm(this.translateService.instant("Cancelar planejamento"), this.translateService.instant("Dados incluídos/alterados nessa tela serão descartados. Deseja cancelar?"))
        .then((confirmed) => {
          if(confirmed){
            this.cancel();
          }
        });
  }

  onSaveClick(){

    if(this.hasAnyMultipleAmountError()){
      this.showMultipleAmountErrorMessage();
      return;
    }

    this.confirm(this.translateService.instant("Salvar planejamento"), this.translateService.instant("Planejamento ficará salvo mas NÃO será enviado para o mainframe. (Para envio ao mainframe deve ser utilzada a opção Enviar) Deseja salvar?"))
        .then((confirmed) => {
          if(confirmed)
            this.save();
        });
  }

  onSendClick(){

    if(this.hasAnyMultipleAmountError()){
      this.showMultipleAmountErrorMessage();
      return;
    }

    var model = this.getUpInsertPlanningModel();

    if(!this.canSendPlanning(model)){
      return;
    }
    this.confirm(this.translateService.instant("Enviar planejamento"), this.translateService.instant("Planejamento será criado na tela do programador da peça. Deseja enviar?"))
        .then((confirmed) => {
          if(confirmed)
            this.send(model);
        });
  }

  onFinalizeClick(){
    this.confirm(this.translateService.instant("Finalizar planejamento"), this.translateService.instant("Ao finalizar não será mais possível alteração nos dados e envio para o mainframe. Deseja finalizar?"))
        .then((confirmed) => {
          if(confirmed)
            this.finalize();
        });
  }

  confirm(
    title: string,
    message: string,
    btnOkText: string = this.translateService.instant("Sim"),
    btnCancelText: string = this.translateService.instant("Não"),
    dialogSize: 'sm' | 'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  cancel(){
    this.goToPlanningList();
  }

  send(model: UpInsertPlanningModel){
    this.spinner.show();

    this.upInsertPlanning(model).subscribe(
      (success) =>{
        this.sendPlanning(model.id);
      },
      (error) =>{
        this.error(error);
      });
  }

  sendPlanning(planningId: string | null){
    if(!planningId){
      return;
    }

    this.dataService.sendPlanning(planningId).subscribe(
      (success) =>{
        this.success(this.translateService.instant('Planejamento enviado com sucesso!'));
      },
      (error) =>{
        this.error(error);
      });
  }

  finalize(){
    if(!this.planningId){
      return;
    }

    this.dataService.completePlanning(this.planningId).subscribe(
      (success) =>{
        this.completePlanningSuccess();
      },
      (error) =>{
        this.error(error);
      });
  }

  completePlanningSuccess(){
    this.success(this.translateService.instant('Planejamento finalizado com sucesso!'));
    this.goToPlanningList();
  }

  goToPlanningList(){
    this.router.navigate(['/planning']);
  }

  save(){
    this.spinner.show();
    let model = this.getUpInsertPlanningModel();
    this.upInsertPlanning(model).subscribe(
      (success) =>{
        this.success(this.translateService.instant('Planejamento salvo com sucesso!'));
      },
      (error) =>{
        this.error(error);
      });
  }

  upInsertPlanning(model: UpInsertPlanningModel): Observable<ResponseResult<UpInsertPlanningModel>>{
    if(this.creating)
      return this.insertPlanning(model);

    return this.updatePlanning(model);
  }

  insertPlanning(model: UpInsertPlanningModel): Observable<ResponseResult<UpInsertPlanningModel>>{
    return this.dataService.insertPlanning(model);
  }

  updatePlanning(model: UpInsertPlanningModel): Observable<ResponseResult<UpInsertPlanningModel>>{
    return this.dataService.updatePlanning(model);
  }

  getUpInsertPlanningModel() : UpInsertPlanningModel{
    return {
      id: this.planning.id,
      code: this.planning.code,
      dealerId: this.planning.quotation.dealer?.dealerId ?? null,
      forecastLimit: this.planning.forecastLimit,
      partsPlanning: this.getUpInsertPartPlanningModel(this.partsPlanning),
      quotationId: this.planning.quotation.id,
      status: this.planning.status
    };
  }

  getUpInsertPartPlanningModel(partsPlanning: PartPlanning[]) : UpInsertPartPlanningModel[]{
    let upInsertPartsPlanning: UpInsertPartPlanningModel[] = partsPlanning.map(p => {
      return {
        id: p.id,
        developerName: null,
        partQuotationId: p.partQuotation.id,
        partId: p.partQuotation.part.id,
        warningsPart: [],
        weeksPartPlanning: this.getWeekPartPlanning(p.weeksPartPlanning)
      };
    });

    return upInsertPartsPlanning;
  }

  getWeekPartPlanning(weeksPartPlanning: WeekPartPlanning[]) : UpInsertWeekPartPlanningModel[]{
    return weeksPartPlanning.map(w => ({
      ...w
    }));
  }

  compareWeek(w1: WeekPartPlanning, w2: WeekPartPlanning) : number {
    let w1Identifier = (w1.year * 100) + w1.week;
    let w2Identifier = (w2.year * 100) + w2.week;

    if (w1Identifier < w2Identifier) {
      return -1;
    }
    if (w1Identifier > w2Identifier) {
      return 1;
    }

    return 0;
  }

  disableWeek(statusId: string) : boolean{
    if(this.creating){
      return false;
    }

    if(!this.planning){
      return false;
    }

    if(!this.planning.status){
      return false;
    }

    if(!this.planning.status){
      return false;
    }

    if (statusId.toLowerCase() === EnumStatusWeekPartPlanning.Enabled.toLowerCase()) {
      return false;
    }

    if(statusId.toLowerCase() === EnumStatusWeekPartPlanning.Firm.toLowerCase() && this.planning.status.id.toLowerCase() === EnumPlanningStatus.Registered.toLowerCase()){
      return false;
    }

    if(statusId.toLowerCase() === EnumStatusWeekPartPlanning.Current.toLowerCase() && this.planning.status.id.toLowerCase() === EnumPlanningStatus.Registered.toLowerCase()){
      return false;
    }

    return true;
  }

  calculatePlanningQuantity(){
    if(!this.planning){
      return;
    }

    if(!this.planning.partsPlanning){
      return;
    }

    this.planning.partsPlanning.forEach(p => {
      this.totalizePlanningAmout(p);
    });
  }

  onQuotationClick(quotationId: string | null){

    if(!quotationId){
      return;
    }

    const modalRef = this.modalService.open(QuotationDialogComponent, {size: 'md' });
    modalRef.componentInstance.goToQuotation = this.goToQuotation.bind(this);
    modalRef.componentInstance.saveAndGoToQuotation = this.saveAndGoToQuotation.bind(this);
    modalRef.componentInstance.quotationId = quotationId;
  }

  goToQuotation(quotationId: string){
    this.router.navigate(['../editQuotation', quotationId]);
  }

  getStatusColorClass(statusId: any): string {
    if(!statusId){
      return '';
    }

    switch (statusId.toLowerCase()) {
      case EnumPlanningStatus.Transmitted.toLowerCase():
        return 'text-color-transmitted';
      case EnumPlanningStatus.Registered.toLowerCase():
        return 'text-color-registered';
      case EnumPlanningStatus.Finished.toLowerCase():
        return 'text-color-completed';
      default:
        return '';
    }
  }

  openWarningsModal(warningsPart: WarningPart[]){
    const modalRef = this.modalService.open(WarningsPartComponent, {size: 'sm' });
    modalRef.componentInstance.warningsPart = warningsPart;
    modalRef.componentInstance.titleText = this.translateService.instant('Mudança na situação da peça');
    modalRef.componentInstance.messageText = this.translateService.instant('Possíveis bloqueios existentes para esta peça:');
  }

  showWarning(warningsPart: WarningPart[]) : boolean{
    if(!warningsPart)
      return false;

    if(warningsPart.length == 0)
      return false;

    return true;
  }

  openOtherPlanningsModal(planningsWithPart: PlanningHasPart[]){
    const modalRef = this.modalService.open(PartOtherPlanningsComponent, {size: 'lg' });
    modalRef.componentInstance.loadPlannings(planningsWithPart);
  }

  success(message: string){
    this.spinner.hide();
    this.toastr.success(message, this.translateService.instant('Sucesso!'));
    this.goToPlanningList();
  }

  error(error: any){
    this.spinner.hide();
    if(!error.error){
      return;
    }

    if(error.error.message){
      this.toastr.error(error.error.message.toString(), this.translateService.instant('Erro'));
    }

    if(error.error.title){
      this.toastr.error(error.error.title.toString(), this.translateService.instant('Erro'));
    }

    if(error.error.data){
      this.toastr.error(error.error.data.toString(), this.translateService.instant('Erro'));
    }
  }

  showOtherPlannings(otherPlannings: PlanningHasPart[]) : boolean{
    if(!otherPlannings)
      return false;

    if(otherPlannings.length == 0)
      return false;

    return true;
  }

  openPartDetailsModal(partPlanning: PartPlanning) {
    const modalRef = this.modalService.open(PartDetailsComponent, {size: 'xl' });
    modalRef.componentInstance.partPlanning = partPlanning;
  }

  openPartsInfoModal(){
    const modalRef = this.modalService.open(PartsInformationComponent, {size: 'xl' });
    modalRef.componentInstance.partsPlanning = this.partsPlanning;
  }

  openInconsistenciesModal(){
    const modalRef = this.modalService.open(PlanningInconsistenciesComponent, {size: 'lg' });
    modalRef.componentInstance.inconsistencies = this.inconsistencies;
    modalRef.result.finally(() => {
      this.getInconsistencies();
    });
  }

  getInconsistencies() {
    if(!this.planningId) {
      return;
    }

    this.inconsistencies = [];

    this.spinner.show();

    this.dataService.getInconsistencies(this.planningId).subscribe(
      response => {
        this.spinner.hide();

        if(!response)
          return;

        if(!response.data)
          return;

        this.inconsistencies = response.data.filter(i => i.warningActive === true);
        this.setNumberOfInconsistencies();
      });
  }

  setNumberOfInconsistencies(){
    this.numberOfInconsistencies = '';

    if(!this.inconsistencies){
      return;
    }

    if(this.inconsistencies.length === 0){
      return;
    }

    if(this.inconsistencies.length > 99){
      this.numberOfInconsistencies = "99+"
      return;
    }

    this.numberOfInconsistencies = this.inconsistencies.length.toString();
  }

  successAndGoToQuotation(message: string, quotationId: string){
    this.spinner.hide();
    this.toastr.success(message, this.translateService.instant('Sucesso!'));
    this.goToQuotation(quotationId);
  }

  saveAndGoToQuotation(quotationId: string){
    this.spinner.show();
    let model = this.getUpInsertPlanningModel();
    this.upInsertPlanning(model).subscribe(
      (success) =>{
        this.successAndGoToQuotation(this.translateService.instant('Planejamento salvo com sucesso!'), quotationId);
      },
      (error) =>{
        this.error(error);
      });
  }

  getTooltipContent(rowIndex: number, columnIndex: number): string {
    const title = this.translateService.instant('Informar qtd peças');

    if(!this.planning){
      return title;
    }

    if(!this.planning.partsPlanning[rowIndex]){
      return title;
    }

    if(!this.planning.partsPlanning[rowIndex].weeksPartPlanning[columnIndex]){
      return title;
    }

    const week = this.planning.partsPlanning[rowIndex].weeksPartPlanning[columnIndex];

    if(week.multipleAmountError){
      return `${this.translateService.instant('Quantidade deve ser múltiplo de')} ${week.multipleAmountError}`;
    }

    var deadlineText: string | null = null;
    var sendDateText: string | null = null;
    if (week.deadLineChange && week.deadLineChange.toString() !== '0001-01-01T00:00:00') {
      deadlineText = `${this.translateService.instant('Qtd Peças - Prazo máximo para alteração:')} ${this.datePipe.transform(week.deadLineChange, 'dd/MM/yyyy')};`;
    }

    if(week.sendDate){
      sendDateText = `${this.translateService.instant('Data último envio p/ cics:')} ${this.datePipe.transform(week.sendDate, 'dd/MM/yyyy')};`;
    }

    if(deadlineText && sendDateText){
      return `${title}
              ${deadlineText}
              ${sendDateText}`;
    }

    if(deadlineText){
      return `${title}
              ${deadlineText}`;
    }

    if(sendDateText){
      return `${title}
              ${sendDateText}`;
    }

    return title;
  }

  onDeleteClick(){
    if(!this.planningId){
      return;
    }

    const modalRef = this.modalService.open(DeletePlanningComponent, { size: 'md' });
    modalRef.componentInstance.planningId = this.planningId;

    modalRef.result.then((response) => {
      if(response){
        this.goToPlanningList();
      }
    });
  }

  isPlanningRegistered(statusId: any): boolean {
    if(this.creating){
      return false;
    }

    if(!statusId){
      return false;
    }

    if(statusId.toLowerCase() === EnumPlanningStatus.Registered.toLowerCase()){
      return true;
    }

    return false;
  }

  isPlanningTransmitted(statusId: any): boolean {
    if(this.creating){
      return false;
    }

    if(!statusId){
      return false;
    }

    if(statusId.toLowerCase() === EnumPlanningStatus.Transmitted.toLowerCase()){
      return true;
    }

    return false;
  }

  setCanAccess(){
    var isSalesRepresentative = this.LocalStorage.searchGroup(environment.SALES_REPRESENTATIVE_GROUP_ID);
    var isExternal = this.LocalStorage.searchGroup(environment.EXTERNAL_USERS_GROUP_ID);
    var isProduction = stringToBool(environment.IS_PRODUCTION);

    this.canAccess = isSalesRepresentative || (isExternal && !isProduction);
  }

  setLoadedModel(){
    this.loadedModel = this.getUpInsertPlanningModel();
  }

  canSendPlanning(model: UpInsertPlanningModel): boolean{
    if(!this.loadedModel){
      return true;
    }

    if(!this.loadedModel.status){
      return true;
    }

    if(!isEqual(model, this.loadedModel)){
      return true;
    }

    if(this.loadedModel.status.id.toLowerCase() !== EnumPlanningStatus.Transmitted.toLowerCase()){
      return true;
    }

    if(this.loadedModel.partsPlanning.some(p => p.weeksPartPlanning?.some(w => this.canSendWeek(w)))){
      return true;
    }

    this.toastr.warning('Não houve alteração no planejamento', this.translateService.instant('Aviso!'));
    return false;
  }

  canSendWeek(week: UpInsertWeekPartPlanningModel): boolean {
    if(!week.isUpdated){
      return false;
    }

    if(week.amount === null){
      return false;
    }

    return true;
  }

  setSizes() {
    if(!this.divCardPrincipal)
      return;

    const divCardPrincipalWidth = this.divCardPrincipal.nativeElement.offsetWidth;

    if(!this.divPlanningGrid)
      return;
    this.divPlanningGrid.nativeElement.style.width = `${divCardPrincipalWidth * 0.935}px`;

    const vhValue: number = window.innerHeight / this.totalPercentage;
    let totalHeight = this.gridPercentageHeight * vhValue;
    this.fillerPlanningGrid.nativeElement.style.height = `${totalHeight}px`;

    if(!this.partsPlanning)
      return;

    if(this.partsPlanning.length <= 0)
      return;

    let gridHeight = (this.partsPlanning.length * this.gridLineHeight) + this.gridHeaderHeight;
    if(gridHeight > totalHeight)
      gridHeight = totalHeight;
    let fillerHeight = totalHeight - gridHeight;

    if(fillerHeight < 0){
      fillerHeight = 0;
      this.divPlanningGrid.nativeElement.style.height = `490px`;
    }

    this.divPlanningGrid.nativeElement.style.height = `${gridHeight}px`;
    this.fillerPlanningGrid.nativeElement.style.height = `${fillerHeight}px`

    if(!this.planningGrid)
      return;

    this.planningGrid.nativeElement.style.width = `${divCardPrincipalWidth * 0.935}px`;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;

    this.setSizes();
    const divElement = this.elementRef.nativeElement.querySelector('.div-card-principal');

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;
      }
    });

    resizeObserver.observe(divElement);
  }

  verifyReplacedValue(rowIndex: number, columnIndex: number){

    let partPlanning = this.planning.partsPlanning[rowIndex];
    let weekPartPlanning = partPlanning.weeksPartPlanning[columnIndex];
    let partQuotation = partPlanning.partQuotation;

    weekPartPlanning.multipleAmountError = 0;

    if(!this.isPartQuotationValidToCheckMultipleAmount(partQuotation)){
      return;
    }

    if(weekPartPlanning.amount === null){
      weekPartPlanning.amount = partQuotation.replacedMin;
    }

    if(!this.verifyMultiple(partQuotation, weekPartPlanning.amount)){
      weekPartPlanning.multipleAmountError = partQuotation.replacedMin;
      return;
    }

    var otherReplacedParts = this.partsPlanning.filter(x => x.partQuotation.replacedToPartId === partQuotation?.replacedToPartId);

    if(!otherReplacedParts){
      return;
    }

    otherReplacedParts.forEach(x => this.verifyProportion(x, partPlanning, columnIndex));
  }

  isPartQuotationValidToCheckMultipleAmount(partQuotation: PartQuotation): boolean {
    if(!partQuotation){
      return false;
    }

    if(!partQuotation.replacedToPartId){
      return false;
    }

    if(partQuotation.replacedToPartId === ''){
      return false;
    }

    if(!partQuotation.replacedMin){
      return false;
    }

    if(partQuotation.replacedMin === 0){
      return false;
    }

    return true;
  }

  verifyMultiple(partQuotation: PartQuotation, amount: number): boolean{

    if(!this.isPartQuotationValidToCheckMultipleAmount(partQuotation)){
      return true;
    }

    if(amount === 0){
      return true;
    }

    if(this.isMultiple(amount, partQuotation.replacedMin)){
      return true;
    }

    return false;
  }

  isMultiple(number: number, multipleOf: number): boolean {
    return number % multipleOf === 0;
  }

  verifyProportion(otherPart: PartPlanning, firstPart: PartPlanning, columnIndex: number){
    if(!otherPart){
      return;
    }

    let otherPartQuotation = otherPart.partQuotation;

    if(!otherPartQuotation){
      return;
    }

    if(!otherPartQuotation.replacedMin){
      return;
    }

    if(otherPartQuotation.replacedMin === 0){
      return;
    }

    let firstPartQuotation = firstPart.partQuotation;

    if(!firstPartQuotation){
      return;
    }

    if(!firstPartQuotation.replacedMin){
      return;
    }

    if(firstPartQuotation.replacedMin === 0){
      return;
    }

    let weekOtherPartPlanning = otherPart.weeksPartPlanning[columnIndex];
    let weekFirstPartPlanning = firstPart.weeksPartPlanning[columnIndex];

    var modifier = otherPartQuotation.replacedMin / firstPartQuotation.replacedMin;

    weekOtherPartPlanning.amount = weekFirstPartPlanning.amount * modifier;
    weekOtherPartPlanning.multipleAmountError = 0;

    this.totalizePlanningAmout(otherPart);
  }

  hasMultipleAmountError(multipleAmountError: number): boolean {
    if(!multipleAmountError){
      return false;
    }

    if(multipleAmountError === 0){
      return false;
    }

    return true;
  }

  hasAnyMultipleAmountError(): boolean {
    let planning = this.planning;

    if(!planning){
      return false;
    }

    if(!planning.partsPlanning){
      return false;
    }

    if(this.planning.partsPlanning.some(
      (partPlanning) =>
        partPlanning.weeksPartPlanning &&
        partPlanning.weeksPartPlanning.some(
          (weekPartPlanning) =>
            weekPartPlanning.multipleAmountError !== null &&
            weekPartPlanning.multipleAmountError > 0
        )
    )) {
      return true;
    }

    return false;
  }

  showMultipleAmountErrorMessage(){
    this.toastr.error(this.translateService.instant('Existem peças com quantidade incorreta. Verifique!'), this.translateService.instant('Erro'));
  }

  downloadReport() {
		this.confirm(this.translateService.instant("Confirmar"), this.translateService.instant("Deseja fazer download do arquivo excel?"))
      .then((confirmed) => {
        if(confirmed){
          if(!this.planningId){
            return;
          }
          this.dataService.downloadReport(this.planningId, this.getSortField(), this.getSortDirection()).subscribe(
            response => {
              let fileName = this.parseHeaders(response.headers);
              this.dataService.downloadFile(response.body, 'text/csv', fileName);
            }, (error) => {
              let erro = <any>error;
              if(error?.status == 400)
                this.toastr.error(erro.error.details[0].erros[0], this.translateService.instant('Erro'), { timeOut: 5000, extendedTimeOut: 5000 });
              else
                this.toastr.error(error.error.message, this.translateService.instant('Erro'), { timeOut: 5000, extendedTimeOut: 5000 })
          });
        }
      });
	}

  parseHeaders(responseHeader: any) {
		let contentDisposition = responseHeader.get('content-disposition');
    return decodeURIComponent(contentDisposition.split(';')[1].split('=')[1]);
	}

  getSortField(): string {
    if(!this.sortStateIsValid()){
      return this.defaultSortStateActive;
    }

    return this.sortState.active
  }

  getSortDirection(): string {
    if(!this.sortStateIsValid()){
      return this.defaultSortStateDirection;
    }

    return this.sortState.direction
  }

  sortStateIsValid() : boolean {
    if(!this.sortState){
      return false;
    }

    if(!this.sortState.direction){
      return false;
    }

    if(!this.sortState.active){
      return false;
    }

    return true;
  }

  totalizePlanningAmout(part: PartPlanning){
    part.amount = part.weeksPartPlanning.reduce((totalAmount, week) => totalAmount + week.amount, 0);
  }

  getNextPagePartsPlanning(){

    if(this.loadingParts){
      return;
    }

    if(this.allLoaded){
      return;
    }

    this.page++;
    this.getPartsPlanningFromPlanningId();
  }

  onDivScroll() {
    if(!this.planningId)
      return;

    const div = this.divPlanningGrid.nativeElement;
    const atBottom = div.scrollHeight - div.scrollTop <= div.clientHeight;

    if (atBottom) {
      this.getNextPagePartsPlanning();
    }
  }

  getPartsPlanningFromPlanningId(){
    if(!this.planningId)
      return;

    this.loadingParts = true;
    this.dataService.getPartsPlanningFromPlanningId(this.planningId, this.page, this.limit).subscribe(
      response => {
        if(!response)
          return;

        var responseData = response.data;
        var partsPlanning = responseData.data;

        if(this.page === this.firstPage){
          this.partsPlanning = [];
        }

        this.partsPlanning.push(...response.data.data);
        this.dataSource = new MatTableDataSource<PartPlanning>(this.partsPlanning);
        this.planning.partsPlanning = this.partsPlanning;
        this.orderColumns();
        this.calculatePlanningQuantity();
        this.setLoadedModel();
        this.loadingParts = false;
        this.totalParts = response.data.total;
        this.setAllLoaded(partsPlanning.length);
      });
  }

  setAllLoaded(results: number){
    this.allLoaded = (results < this.limit) || (this.totalParts === this.partsPlanning.length);
  }

  orderColumns(){
    this.partsPlanning.forEach(partPlanning => {
      partPlanning.weeksPartPlanning = partPlanning.weeksPartPlanning.sort(this.compareWeek);
    });
  }

  getActionButtonTooltipContent(): string{
    if(this.creating){
      return "";
    }

    if(this.allLoaded){
      return "";
    }

    return "";
  }
}
