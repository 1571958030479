import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { systems } from 'src/app/constants/page-names.const';
import { LocalStorageUtils } from 'src/app/shared/utils/localstorage';
import { AppInsightsService } from 'src/azure-monitor';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parameters-systems',
  templateUrl: './parameters-systems.component.html',
  styleUrl: './parameters-systems.component.css'
})
export class ParametersSystemsComponent implements OnInit {

  @Output() clickMenuItem = new EventEmitter<string>();

  isIt = false;

  public LocalStorage = new LocalStorageUtils();

  constructor() {
    this.setIsIt();
  }

  ngOnInit(): void {
    this.clickMenuItem.emit('parametersSystems');
    AppInsightsService.logPageName(systems);
  }

  setIsIt(){
    this.isIt = this.LocalStorage.searchGroup(environment.IT_GROUP_ID);
  }
}
