import { Injectable } from '@angular/core';
import { Newsletter } from '../models/newsletter';
import { Observable, catchError, map } from 'rxjs';
import { ResponseResult } from 'src/app/services/grid-data-source.service';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/services/base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PromotionNewsletterService extends BaseService  {
 

  constructor(private http: HttpClient) {
    super();
  }

  createNewsletter(newsletter: Newsletter): Observable<ResponseResult<Newsletter>> {
   
    return this.http
      .post<ResponseResult<Newsletter>>(`${environment.URL_API_PARTS_ORDER_BOOK}NewsletterPromotion`, newsletter, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract), 
        catchError(super.serviceError));
  }

  deleteNewsletter(email: string): Observable<ResponseResult<Newsletter>> {
   
    return this.http
      .delete<ResponseResult<Newsletter>>(`${environment.URL_API_PARTS_ORDER_BOOK}NewsletterPromotion/${email}`, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract), 
        catchError(super.serviceError));
  }

}
