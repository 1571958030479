import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { Promotion } from '../../models/promotion';
import { ActivatedRoute, Router } from '@angular/router';
import { PromotionService } from '../../services/promotion.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartPromotionCreateComponent } from 'src/app/modules/part-promotion/component/part-promotion-create/part-promotion-create.component';
import { GetTranslationService } from 'src/app/shared/utils/get-translation.service';
import { PartPromotionService } from 'src/app/modules/part-promotion/services/part-promotion.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDeleteReasonDialogComponent } from 'src/app/shared/confirmation-delete-reason-dialog/confirmation-delete-reason-dialog.component';
import { DatePipe } from '@angular/common';
import { PartPromotionDeleteComponent } from 'src/app/modules/part-promotion/component/part-promotion-delete/part-promotion-delete.component';
import { EnumPromotionStatus } from 'src/app/shared/models/enumPromotionStatus';

@Component({
  selector: 'app-promotion-detail',
  templateUrl: './promotion-detail.component.html',
  styleUrls: ['./promotion-detail.component.css']
})
export class PromotionDetailComponent extends BaseService  implements OnInit {


  maskDate: string = 'dd/MM/YYYY';
  promotionId: string = '';
  promotion!: Promotion;
  promotionName: string = "";
  promotionInitialDate!: Date ;
  promotionFinalDate!: Date ;
  isPricingManager = false;
  isInactiveOrClosed = false;
  datePipe: DatePipe = new DatePipe('en-US');

  constructor(private route: ActivatedRoute,
              private promotionService: PromotionService,
              private getTranslationSvc: GetTranslationService,
              private toastr: ToastrService,
              private modalService: NgbModal,
              public dataService: PartPromotionService,
              private spinner: NgxSpinnerService,
              private router: Router) {
    super();

    this.setIsPricingManager();

    this.route.params.subscribe(params => {
      this.promotionId = params['promotionId'];
    });

    this.promotionService.getPromotionById(this.promotionId).subscribe(
      response =>{
        this.promotion = response.data;
        this.promotionName = this.promotion.name;
        this.promotionInitialDate = this.promotion.initialDate;
        this.promotionFinalDate = this.promotion.finalDate;
        this.isInactiveOrClosed = this.isInactiveOrClosedStatus(this.promotion.promotionStatus.id.toString());
    });
  }

  ngOnInit(): void {

   }


   downloadReport() {
		if (confirm(this.getTranslationSvc.getTranslation("Deseja fazer download do arquivo .csv?")))
			this.promotionService.downloadReport(this.promotionId).subscribe(
				response => {
					let fileName = this.parseHeaders(response.headers)
					this.promotionService.downloadFile(response.body, 'text/csv', fileName);
				}, (error) => {
          let erro = <any>error;
          if(error?.status == 400)
            this.toastr.error(erro.error.details[0].erros[0], this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 });
          else
            this.toastr.error(error.error.message, this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 })
      })
	  }

    parseHeaders(responseHeader: any) {
      let contentDisposition = responseHeader.get('content-disposition');
      return contentDisposition.split(';')[1].split('=')[1];
    }

    openCreateModal() {
      const modalRef = this.modalService.open(PartPromotionCreateComponent, {size: 'lg' });
      modalRef.componentInstance.promotionId = this.promotionId;
    }

    deleteAllPartPromotion(){
      const modalRef = this.modalService.open(PartPromotionDeleteComponent, {size: 'md' });
      modalRef.componentInstance.partId = '00000000-0000-0000-0000-000000000000';
      modalRef.componentInstance.promotionId = this.promotionId;
      modalRef.componentInstance.title = this.getTranslationSvc.getTranslation("Exclusão de todas as peças");
      modalRef.componentInstance.message = this.getTranslationSvc.getTranslation("Confirma a exclusão de todas as peças da promoção?");
      modalRef.componentInstance.dateLabel = this.getTranslationSvc.getTranslation("Removidas da promo a partir de");
      modalRef.componentInstance.refreshGrid.subscribe(() => {
        location.reload();
      });
    }

    inactivePromotion(){
      this.confirm(this.getTranslationSvc.getTranslation("Inativação"), this.getTranslationSvc.getTranslation("Confirma a inativação da promoção?"))
        .then((confirmed) => {
          if(confirmed)
            this.deletePromotion();
        });
    }

    deletePromotion() {
      const modalRef = this.modalService.open(ConfirmationDeleteReasonDialogComponent);
      modalRef.result.then((result) => {
        this.spinner.show();
        const reason = result;
        this.promotionService.deletePromotion(this.promotionId, reason).subscribe(
          () =>{
            this.spinner.hide();
            this.toastr.success(this.getTranslationSvc.getTranslation("Promoção inativada com sucesso!"))
            this.router.navigate(['/promotionGrid']);
          },
          (error) =>{
            this.spinner.hide();
            let erro = <any>error;
            if(error?.status == 400)
              this.toastr.error(erro.error.details[0].erros[0], this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 });
            else
              this.toastr.error(error.error.message, this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 })

             return;
          }
         );


      });
    }

    confirm(
      title: string,
      message: string,
      btnOkText: string = this.getTranslationSvc.getTranslation("Sim"),
      btnCancelText: string = this.getTranslationSvc.getTranslation("Não"),
      dialogSize: 'sm' | 'sm' = 'sm'): Promise<boolean> {
      const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.message = message;
      modalRef.componentInstance.btnOkText = btnOkText;
      modalRef.componentInstance.btnCancelText = btnCancelText;

      return modalRef.result;
    }

    setIsPricingManager(){
      this.isPricingManager = this.LocalStorage.searchGroup(environment.PRICING_MANAGER_GROUP_ID);
    }

    sendNewsletter(){
      this.confirmMd(this.getTranslationSvc.getTranslation("Enviar boletim da promoção"), `${this.getTranslationSvc.getTranslation("Início da promoção em: ")} ${this.datePipe.transform(this.promotionInitialDate, 'dd/MM/yyyy')}\n${this.getTranslationSvc.getTranslation("Confirma o envio do boletim da promoção?")}`)
      .then((confirmed) => {
        if(confirmed){
          this.spinner.show();
          this.promotionService.sendNewsletter(this.promotionId).subscribe({
            next: (success: any) =>{
              this.spinner.hide();
              this.toastr.success(success.message,this.getTranslationSvc.getTranslation('Sucesso'), { timeOut: 5000, extendedTimeOut: 5000, enableHtml: true })
            },
            error: (error: any) => {
              this.spinner.hide();
              let erro = <any>error;
              if(error?.status == 400)
                this.toastr.error(erro.error.details[0].erros[0], this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 });
              else
                this.toastr.error(error.error.message, this.getTranslationSvc.getTranslation('Erro'), { timeOut: 5000, extendedTimeOut: 5000 })
              }
          });
        }
      });
    }

    isInactiveOrClosedStatus(statusId: string): boolean {
      if(statusId.toLowerCase() === EnumPromotionStatus.Inactive.toLowerCase())
          return true;

      if(statusId.toLowerCase() === EnumPromotionStatus.Closed.toLowerCase())
        return true;

      return false;
    }

    confirmMd(
      title: string,
      message: string,
      btnOkText: string = this.getTranslationSvc.getTranslation("Sim"),
      btnCancelText: string = this.getTranslationSvc.getTranslation("Não"),
      dialogSize: 'md' | 'md' = 'md'): Promise<boolean> {
      const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.message = message;
      modalRef.componentInstance.btnOkText = btnOkText;
      modalRef.componentInstance.btnCancelText = btnCancelText;

      return modalRef.result;
    }
}
