import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map } from "rxjs";
import { BaseService } from "src/app/services/base.service";
import { PagedResponse, ResponseResult } from "src/app/services/grid-data-source.service";
import { environment } from "src/environments/environment";
import { GetTranslationService } from "src/app/shared/utils/get-translation.service";
import { DealerGroup } from "../models/dealer-group";
import { CommercialParameters } from "../models/commercial-parameters";
import { NewsLetter } from "../models/news-letter";

@Injectable({
  providedIn: 'root'
})

export class ParametersService extends BaseService {

  constructor(private http: HttpClient,
    public getTranslationSvc: GetTranslationService) {
    super();
  }

  listDayWeekDealerGroup(): Observable<PagedResponse<DealerGroup>> {
    return this.http
    .get<PagedResponse<DealerGroup>>(`${environment.URL_API_PARTS_ORDER_BOOK}DayWeekDealerGroup`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  putCommercialParameters(model: CommercialParameters): Observable<ResponseResult<CommercialParameters>> {
    return this.http
      .put<ResponseResult<CommercialParameters>>(`${environment.URL_API_PARTS_ORDER_BOOK}CommercialParameters`, model, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  putDayWeekDealerGroup(model: DealerGroup[]): Observable<ResponseResult<DealerGroup>> {
    return this.http
      .put<ResponseResult<DealerGroup>>(`${environment.URL_API_PARTS_ORDER_BOOK}DayWeekDealerGroup`, model, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  getNewsletters(): Observable<ResponseResult<NewsLetter[]>> {
    return this.http
    .get<ResponseResult<NewsLetter[]>>(`${environment.URL_API_PARTS_ORDER_BOOK}NewsletterPromotion`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  searchNewsletters(email: string): Observable<ResponseResult<NewsLetter[]>> {
    return this.http
    .get<ResponseResult<NewsLetter[]>>(`${environment.URL_API_PARTS_ORDER_BOOK}NewsletterPromotion?email=${email}`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }
}
