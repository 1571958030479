import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PartPromotion } from '../../promotion/models/partPromotion';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PagedResponse, ResponseResult } from 'src/app/services/grid-data-source.service';
import { Part } from '../../promotion/models/part';



@Injectable({
  providedIn: 'root'
})
export class PartPromotionService extends BaseService {

  promotionId!: string;
  partPromotion : PartPromotion[] = [];


  constructor(private http: HttpClient) {
    super();

   }

   createPartPromotion(partPromotion: PartPromotion): Observable<ResponseResult<PartPromotion>> {
    return this.http
      .post<ResponseResult<PartPromotion>>(`${environment.URL_API_PARTS_ORDER_BOOK}PartPromotion`, partPromotion, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  deletePartPromotion(id: string, date: string, promotionId: string): Observable<ResponseResult<PartPromotion>> {
    return this.http
      .delete<ResponseResult<PartPromotion>>(`${environment.URL_API_PARTS_ORDER_BOOK}PartPromotion/${id}/${promotionId}/${date}`, {headers: super.GetAuthTokenHeader().headers})
      .pipe(
        map(super.extract),
        catchError(super.serviceError));
  }

  upload(formData: FormData): Observable<PartPromotion>{
    return this.http.post(`${environment.URL_API_PARTS_ORDER_BOOK}PartPromotion/Upload`, formData, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(this.extract),
      catchError(this.serviceError));
  }

  getAllPromotion(filterObject:any): Observable<PagedResponse<PartPromotion>> {
    return this.http
    .get<PagedResponse<PartPromotion>>(`${environment.URL_API_PARTS_ORDER_BOOK}PartPromotion`, {params: filterObject, headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  listParts(page: number, pageSize: number, filterObject:any): Observable<PagedResponse<Part>> {
    return this.http
    .get<PagedResponse<Part>>(`${environment.URL_API_PARTS_ORDER_BOOK}Part/${page}/${pageSize}`, {params: filterObject, headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  downloadReport() {
    const headers = {
      'Content-Type': 'application/json',
      'Language-Code': `${this.LocalStorage.getCurrentLanguage()}`,
      'Authorization': `Bearer ${this.LocalStorage.getUserToken()}`,
    }
    return this.http
      .get(`${environment.URL_API_PARTS_ORDER_BOOK}PartPromotion/Download`, {headers: super.GetAuthTokenHeader().headers, responseType: 'arraybuffer', observe: 'response'})
  }

  downloadFile(data: any, type: string, fileName: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

}
