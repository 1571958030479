import { Injectable } from "@angular/core";
import { BaseService } from 'src/app/services/base.service';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { PagedResponse } from "src/app/services/grid-data-source.service";
import { Dealer, DealerGroup, User, UserDealer } from "../models/user-dealer";

@Injectable({
  providedIn: 'root'
})

export class UserDealerService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getAllUserDealer(page: number, pageSize: number, filterObject:any): Observable<PagedResponse<User>> {
    return this.http
    .get<PagedResponse<User>>(`${environment.URL_API_PARTS_ORDER_BOOK}User/${page}/${pageSize}`, {params: filterObject, headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  listUsers(): Observable<PagedResponse<User>> {
    return this.http
    .get<PagedResponse<User>>(`${environment.URL_API_PARTS_ORDER_BOOK}AdUsers/`, {headers: super.GetGraphAccessAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  listDealerGroups(): Observable<PagedResponse<DealerGroup>> {
    return this.http
    .get<PagedResponse<DealerGroup>>(`${environment.URL_API_PARTS_ORDER_BOOK}DealerGroup/`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  listDealers(): Observable<PagedResponse<Dealer>> {
    return this.http
    .get<PagedResponse<Dealer>>(`${environment.URL_API_PARTS_ORDER_BOOK}Dealer/`, {headers: super.GetAuthTokenHeader().headers})
    .pipe(
      map(super.extract),
      catchError(super.serviceError));
  }

  updateUserDealer(userDealer: UserDealer): Observable<UserDealer> {
    return this.http
    .post(`${environment.URL_API_PARTS_ORDER_BOOK}UserDealer/Update`, userDealer, super.GetAuthTokenHeader())
    .pipe(
        map(this.extract),
        catchError(this.serviceError));
  }
}
